<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            EINSTELLUNGEN
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form
              v-model="formValid"
            >
              <v-row>
                <v-col>
                  <v-menu
                    v-model="showVotingEndPickerMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computedEndDate"
                        label="Bewertungs-Ende"
                        readonly
                        v-on="on"
                        @click:clear="votingEnd = null"
                        color="primary"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="votingEnd"
                      @change="showVotingEndPickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-menu
                    v-model="showRentalEndPickerMenu"
                    :close-on-content-click="false"
                    max-width="290"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="computedRentalEndDate"
                        label="Verleih-Ende"
                        readonly
                        v-on="on"
                        @click:clear="rentalEnd = null"
                        color="primary"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="primary"
                      v-model="rentalEnd"
                      @change="showRentalEndPickerMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Ausleihedauer in Tagen"
                    v-model="defaultRentalDays"
                    color="primary"
                    type="number"
                    :rules="[rules.numeric, rules.required]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Maximale Anzahl Verlängerungen"
                    v-model="maxExtends"
                    color="primary"
                    type="number"
                    :rules="[rules.numeric, rules.required]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 py-6">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="
                !formValid ||
                (
                  votingEnd === $moment(settings[0].votingEnd).format('YYYY-MM-DD') &&
                  defaultRentalDays.toString() === settings[0].defaultRentalDays.toString() &&
                  maxExtends.toString() === settings[0].maxExtends.toString() &&
                  rentalEnd == $moment(settings[0].rentalEnd).format('YYYY-MM-DD')
                )
              "
              @click="saveSettings()"
              color="success"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'userEditor',
  data: function () {
    return {
      formValid: false,
      votingEnd: null,
      defaultRentalDays: null,
      maxExtends: null,
      showVotingEndPickerMenu: false,
      rentalEnd: null,
      showRentalEndPickerMenu: false
    }
  },
  mounted () {
    this.adapt()
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    }),
    computedEndDate () {
      return this.votingEnd ? this.$moment(this.votingEnd).format('dddd, DD.MM.YYYY') : ''
    },
    computedRentalEndDate () {
      return this.rentalEnd ? this.$moment(this.rentalEnd).format('dddd, DD.MM.YYYY') : ''
    }
  },
  watch: {
    settings () {
      this.adapt()
    }
  },
  methods: {
    ...mapActions('settings', {
      'patchSettings': 'patch'
    }),
    adapt () {
      if (this.settings) {
        this.votingEnd = this.$moment(this.settings[0].votingEnd).format('YYYY-MM-DD')
        this.defaultRentalDays = this.settings[0].defaultRentalDays
        this.maxExtends = this.settings[0].maxExtends
        this.rentalEnd = this.$moment(this.settings[0].rentalEnd).format('YYYY-MM-DD')
      }
    },
    async saveSettings () {
      const map = {
        votingEnd: this.votingEnd,
        defaultRentalDays: this.defaultRentalDays,
        maxExtends: this.maxExtends,
        rentalEnd: this.rentalEnd
      }
      await this.patchSettings([this.settings[0]._id, map, {}])
    }
  }
}
</script>
