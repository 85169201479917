var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"display-3",attrs:{"align-self":"center"}},[_vm._v(" BEWERTUNGS-EDITOR ")])],1)],1)],1),_c('v-row',[_c('v-col',[(!_vm.inTime)?_c('v-alert',{attrs:{"type":"warning"}},[_vm._v(" Es können keine Bewertungen mehr abgegeben oder bearbeitet werden. ")]):_vm._e(),_c('v-card',[_c('v-card-text',[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.computedUsers,"item-value":"_id","item-text":"firstName","label":"Leser auswählen","rules":[_vm.rules.required],"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny('vote').attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny('vote').attributes.includes('customer')
                    )},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.lastName)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.lastName)+" ")]}}]),model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.computedBooks,"item-value":"_id","item-text":"baseTitle","label":"Buch auswählen","rules":[_vm.rules.required],"error-messages":_vm.bookError},model:{value:(_vm.book),callback:function ($$v) {_vm.book=$$v},expression:"book"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"body-1"},[_vm._v(" Bewertung*"),_c('br'),(!this.value)?_c('span',{staticClass:"body-2 error--text"},[_vm._v("Pflichteingabe.")]):_vm._e()])],1),_c('v-row',[_c('v-col',[_c('v-rating',{attrs:{"color":"primary","hover":"","length":"10"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Zusammenfassung*","disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny('vote').attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny('vote').attributes.includes('description') ||
                      (
                        _vm.selectedCustomer &&
                        _vm.user._id === _vm.selectedCustomer &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn('vote').attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn('vote').attributes.includes('description')
                        )
                      )
                    )},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Begründung*","disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny('vote').attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny('vote').attributes.includes('comment') ||
                      (
                        _vm.selectedCustomer &&
                        _vm.user._id === _vm.selectedCustomer &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn('vote').attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn('vote').attributes.includes('comment')
                        )
                      )
                    )},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4 py-6"},[(_vm.$route.params.id !== 'neu')?[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.deleteVote(_vm.vote._id)}}},[_vm._v(" Löschen ")])]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.formValid || !this.value || !_vm.inTime,"color":"success"},on:{"click":function($event){return _vm.saveVote()}}},[_vm._v(" Speichern ")])],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }