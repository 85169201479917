const state = {
  ac: undefined,
  rules: {
    required: value => !!value || 'Pflichteingabe.',
    shortText: value => (!value || value.length <= 200) || 'Maximal 200 Zeichen.',
    longText: value => (!value || value.length <= 1000) || 'Maximal 1000 Zeichen.',
    loginName: value => {
      const pattern = /^[a-zA-Z0-9]+$/
      return (!value || pattern.test(value) || 'Nur Buchstaben und Zahlen ohne Leerzeichen erlaubt.')
    },
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (!value || pattern.test(value)) || 'Kein gültiges Email-Format'
    },
    password: value => (value && value.length >= 8 && value.length <= 16) || 'Erlaubte Passwörter haben eine Länge von 8-16 Zeichen und keine "*"',
    phone: value => (!value || (parseInt(value) && parseInt(value) >= 1 && value.toString().length >= 8)) || 'Kein gültiges Telefonnummer-Format.',
    numeric: value => (value !== undefined && parseInt(value) !== undefined && parseInt(value) >= 0) || 'Keine gültige Zahl'
  },
  init: true,
  show: false,
  showNav: false,
  showLogin: false,
  section: false
}

const getters = {
  rules: state => {
    return state.rules
  },
  ac: state => {
    return state.ac
  },
  showNav: state => {
    return state.showNav
  },
  showLogin: state => {
    return state.showLogin
  },
  show: state => {
    return state.show
  },
  init: state => {
    return state.init
  },
  section: state => {
    return state.section
  }
}

const mutations = {
  SET_ACCESS_CONTROL: (state, msg) => {
    state.ac = msg
  },
  SET_SHOW_NAV: (state, msg) => {
    state.showNav = msg
  },
  SET_SHOW_LOGIN: (state, msg) => {
    state.showLogin = msg
  },
  SET_SHOW: (state, msg) => {
    state.show = msg
  },
  SET_INIT: (state, msg) => {
    state.init = msg
  },
  SET_SECTION: (state, msg) => {
    state.section = msg
  }
}

const actions = {
}

export default {
  state,
  mutations,
  getters,
  actions
}
