import feathersClient, { makeServicePlugin, BaseModel } from '@/feathers-client'

class Book extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Book'
  // Updates `data.user` to be an instance of the `User` class.
  static setupInstance (data, { models }) {
    if (data.rental) {
      data.rental = new models.api.Rental(data.rental)
    }
    return data
  }
}
const servicePath = 'books'
const servicePlugin = makeServicePlugin({
  Model: Book,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
