<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            DIE JURY
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5">
          <v-card-text>
            <v-row>
              <v-col>
                Du hast Lust, als Jury-Mitglied die „Ulmer Unke“ zu finden? Hier sind die Teilnahmebedingungen:
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ul>
                  <li>Du bist zwischen 10 und 14 Jahre alt.</li>
                  <li>Du liest von März bis Oktober mindestens 6 Bücher.</li>
                  <li>Die Bücher, die von den Jugendlichen des Organisationsteams ausgewählt werden, und der Leseausweis werden Dir kostenlos zur Verfügung gestellt.</li>
                  <li>Du holst die Bücher in der jeweiligen Partner-Buchhandlung in Ulm ab und bringst sie nach maximal 3 Wochen wieder dorthin zurück.</li>
                  <li>Du passt gut auf die entliehenen Bücher auf. Sollte doch einmal eins verloren oder kaputt gehen, ersetzt Du es.</li>
                  <li>Wenn Du ein Buch zu spät zurück gibst, zahlst Du 1€ Mahngebühr pro Woche.</li>
                  <li>Du bewertest jedes Buch mit 1 bis 10 Punkten auf ulmerunke.de.</li>
                  <li>Du schreibst für jedes gelesene Buch eine kurze Inhaltsangabe auf ulmerunke.de.</li>
                  <li>Für jede abgegebene Bewertung bekommst Du einen Stempel in Deinen Lesepass. Wenn Du am Ende der Lesezeit sechs Stempel zusammen hast, bekommst Du ein Unke-Buch geschenkt.</li>
                  <li>Du bist jederzeit per Mail erreichbar, damit man Dich über wichtige Neuigkeiten informieren kann.</li>
                  <li>Du bist automatisch auch für die Lesezeiten in den kommenden Jahren angemeldet, sofern Du dich nicht aktiv abmeldest.</li>
                </ul>
              </v-col>
            </v-row>
             <v-row>
              <v-col>
                Du kannst dich <span class="primary--text clickable" @click="setShowLogin('signup')"> direkt hier auf der Seite als Jury-Mitglied registrieren</span>.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Viel Spaß beim Lesen wünscht dir das Unke Orga Team mit:
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ul>
                  <li>Stadtjugendring Ulm e.V</li>
                  <li>Stadtbibliothek Ulm</li>
                  <li>Bücherstube Jastram</li>
                  <li>Buch Kerler</li>
                  <li>Hugendubel Ulm</li>
                  <li>Buchhandlung Aegis</li>
                  <li>Thalia Ulm/Neu-Ulm</li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Wir freuen uns auf jeden von euch 🙂
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    })
  },
  methods: {
    ...mapMutations({
      'setShowLogin': 'SET_SHOW_LOGIN'
    })
  }
}
</script>
