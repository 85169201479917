<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            NUTZER|NNEN
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'" align-self="center">
            <v-btn
              class="mr-0"
              color="success"
              v-if="
                ac.can(user.role).createAny('customer').granted
              "
              @click="$router.push('/intern/nutzer/editor/neu')"
            >
              <v-icon
                class="mr-3"
                small
              >
                fa fa-plus
              </v-icon>
              Neue|n Nutzer|n hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          label="Suche"
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="ac.can(user.role).updateAny('customer').granted"
      >
        <v-checkbox
          color="primary"
          v-model="showArchivedUsers"
          label="Archivierte Nutzer|nnen anzeigen"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="computedCustomers"
            @update:page="updatePage"
            @update:items-per-page="updateItemsPerPage"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            must-sort
          >
            <template
              v-slot:item.firstName="{ item }"
            >
              {{ item.firstName }} {{ item.lastName }}
              <v-icon
                v-if="user._id === item._id"
                class="ml-2"
                small
              >
                fa fa-user-circle
              </v-icon>
              <v-chip
                small
                :outlined="item.isVerified"
                :color="item.isVerified ? 'success' : 'warning'"
                class="ml-2"
              >
                {{item.isVerified ? 'verifiziert': 'unverifiziert'}}
              </v-chip>
              <v-chip
                class="ml-2"
                small
                :outlined="item.isActive"
                :color="item.isActive ? 'success' : 'warning'"
              >
                {{item.isActive ? 'aktiv': 'inaktiv'}}
              </v-chip>
            </template>
            <template
              v-slot:item.customerId="{ item }"
            >
              {{item.customerId && item.customerId !== '' ? item.customerId : '-'}}
            </template>
            <template
              v-slot:item.role="{ item }"
            >
              {{ roleNames[item.role] }}
            </template>
            <template
              v-slot:item.votes="{ item }"
            >
              {{ item.votes? item.votes.length : 0 }}
            </template>
            <template
              v-slot:item.actions="{ item }"
            >
              <template
                v-if="
                  item.customerId && item.customerId !== '' &&
                  (ac.can(user.role).readAny(item.role).granted ||
                  (ac.can(user.role).readOwn(item.role).granted && user._id === item._id))
                "
              >
                <v-btn
                  @click="$router.push('/intern/leser/' + item._id)"
                  fab
                  color="primary"
                  x-small
                  elevation="2"
                  class="mr-3"
                >
                  <v-icon
                    small
                  >fa fa-book-reader</v-icon>
                </v-btn>
              </template>
              <v-btn
                v-if="
                  ac.can(user.role).updateAny(item.role).granted ||
                  (ac.can(user.role).updateOwn(item.role).granted && user._id === item._id)
                "
                @click="$router.push('/intern/nutzer/editor/' + item._id)"
                color="primary"
                elevation="2"
                x-small
                fab
              >
                <v-icon
                  small
                >
                  fa fa-pen
                </v-icon>
              </v-btn>
              <template
                v-if="
                  item.customerId && item.customerId !== '' &&
                  (ac.can(user.role).updateAny(item.role).granted ||
                  (ac.can(user.role).updateOwn(item.role).granted && user._id === item._id))
                "
              >
                <v-btn
                  v-if="!item.isVerified"
                  class="ml-3"
                  outlined
                  color="warning"
                  small
                  @click="verifyUser(item)"
                >
                  Manuell Verifizieren
                </v-btn>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        ac.can(user.role).readAny('customer').granted &&
        ac.can(user.role).readAny('customer').attributes.includes('*')
      "
    >
      <v-col class="text-right">
        <v-btn
          @click="getCsv()"
          outlined
          color="primary"
        >
          Leser|nnen-Daten herunterladen
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import feathersClient from '@/feathers-client'

export default {
  name: 'users',
  data () {
    return {
      showArchivedUsers: false,
      headers: [
        { text: 'Name', value: 'firstName' },
        { text: 'Ausweis-Nummer', value: 'customerId' },
        { text: 'Rolle', value: 'role' },
        { text: 'Bewertungen', value: 'votes' },
        { text: 'Aktionen', value: 'actions', sortable: false }
      ],
      roleNames: {
        customer: 'Leser|n',
        employee: 'Team',
        admin: 'Admin'
      },
      search: null,
      csvService: undefined,
      page: 1,
      itemsPerPage: 10,
      sortBy: null,
      sortDesc: false
    }
  },
  mounted () {
    // Save current query
    this.$router.options.tmpQuery = this.$route.query
    // Init csv service
    this.csvService = feathersClient.service('csv')
    // Process query
    if (this.$route.query.p) {
      this.page = parseInt(this.$route.query.p)
    }
    if (this.$route.query.i) {
      this.itemsPerPage = parseInt(this.$route.query.i)
    }
    if (this.$route.query.s) {
      this.sortBy = this.$route.query.s
    }
    if (this.$route.query.d) {
      let tmpDesc
      if (this.$route.query.d === 'true') {
        tmpDesc = true
      } else if (this.$route.query.d === 'false') {
        tmpDesc = false
      }
      this.sortDesc = tmpDesc
    }
  },
  computed: {
    computedCustomers () {
      return this.sortByString(this.users.filter(obj =>
        (
          obj.isActive ||
          this.showArchivedUsers
        ) &&
        (
          !this.search ||
          this.search === '' ||
          obj.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
          obj.lastName.toLowerCase().includes(this.search.toLowerCase()) ||
          (obj.customerId && obj.customerId.toLowerCase().includes(this.search.toLowerCase())) ||
          this.roleNames[obj.role].toLowerCase().includes(this.search.toLowerCase()) ||
          obj.votes.length === parseInt(this.search)
        )
      ), 'firstName')
    },
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('users', {
      'users': 'list'
    }),
    ...mapGetters('auth', {
      'user': 'user'
    })
  },
  methods: {
    ...mapActions('users', {
      patchUser: 'patch'
    }),
    updatePage (data) {
      if (parseInt(this.$route.query.p) !== data) {
        this.$router.replace({ query: {
          p: data,
          i: this.itemsPerPage,
          s: this.sortBy,
          d: this.sortDesc
        } })
      }
    },
    updateItemsPerPage (data) {
      if (parseInt(this.$route.query.i) !== data) {
        this.$router.replace({ query: {
          p: this.page,
          i: data,
          s: this.sortBy,
          d: this.sortDesc
        } })
      }
    },
    updateSortBy (data) {
      if (this.$route.query.s !== data) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          s: data,
          d: this.sortDesc
        } })
      }
    },
    updateSortDesc (data) {
      if (this.$route.query.d !== data.toString()) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          s: this.sortBy,
          d: data
        } })
      }
    },
    verifyUser (user) {
      this.patchUser([user._id, { isVerified: true, verifyExpires: null, verifyToken: null }, {}])
    },
    async getCsv () {
      const sheet = await this.csvService.create({ type: 'users' })
      const blob = new Blob(
        [ sheet ],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      )
      this.downloadBlob(blob, 'UnkeJury_' + this.$moment().format('YYYY-MM-DD_hh-mm') + '.xlsx')
    },
    downloadBlob (blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename || 'download'
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.removeEventListener('click', clickHandler)
        }, 150)
      }
      a.addEventListener('click', clickHandler, false)
      a.click()
    }
  },
  watch: {
    '$route.query' () {
      this.$router.options.tmpQuery = this.$route.query
    }
  }
}
</script>
