<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            NUTZER|NNEN EDITOR
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form
              v-model="formValid"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    label="Vorname*"
                    v-model="firstName"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny(role).attributes.includes('*') ||
                        ac.can(user.role).updateAny(role).attributes.includes('firstName') ||
                        (
                          computedUser &&
                          user._id === computedUser._id &&
                          (
                            ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                            ac.can(user.role).updateOwn(role).attributes.includes('firstName')
                          )
                        )
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                <v-text-field
                  label="Nachname*"
                  v-model="lastName"
                  :rules="[rules.required]"
                  :disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('lastName') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('lastName')
                        )
                      )
                    )
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Passwort*"
                  v-model="password"
                  :placeholder="computedUser ? '********': ''"
                  type="password"
                  :aria-disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('password') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('password')
                        )
                      )
                    )
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Benutzername*"
                  v-model="loginName"
                  :rules="[rules.loginName, rules.required]"
                  :error-messages="loginNameErrors"
                  :disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('loginName') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('loginName')
                        )
                      )
                    )
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Email*"
                  v-model="email"
                  :rules="[rules.required]"
                  :error-messages="emailErrors"
                  :disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('email') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('email')
                        )
                      )
                    )
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Telefon"
                  v-model="phone"
                  type="number"
                  :rules="[rules.phone]"
                  :disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('phone') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('phone')
                        )
                      )
                    )
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  ref="birthdayMenu"
                  v-model="showBirthdayMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="computedBirthday"
                      label="Geburtstag"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="birthdayPicker"
                    v-model="birthday"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save"
                    color="primary"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-if="$route.params.id !== 'neu'"
                  :rules="role === 'customer' ? [rules.required] : []"
                  :label="role === 'customer' ? 'Ausweis-Nummer*': 'Ausweis-Nummer'"
                  :error-messages="customerIdErrors"
                  v-model="customerId"
                  :disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('customerId') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('customerId')
                        )
                      )
                    )
                  "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  v-model="role"
                  :items="computedRoles"
                  item-value="role"
                  item-text="text"
                  label="Rolle auswählen"
                  :rules="[rules.required]"
                  :disabled="
                    !(
                      ac.can(user.role).updateAny(role).attributes.includes('*') ||
                      ac.can(user.role).updateAny(role).attributes.includes('role') ||
                      (
                        computedUser &&
                        user._id === computedUser._id &&
                        (
                          ac.can(user.role).updateOwn(role).attributes.includes('*') ||
                          ac.can(user.role).updateOwn(role).attributes.includes('role')
                        )
                      )
                    )
                  "
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4 py-6">
          <template v-if="$route.params.id !== 'neu'">
            <v-btn
              v-if="computedUser.isActive"
              @click="archiveUser()"
              color="warning"
            >
              Archivieren
            </v-btn>
            <v-btn
              v-else
              @click="activateUser()"
              color="primary"
            >
              Aktivieren
            </v-btn>
          </template>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!formValid || loading"
            @click="saveUser()"
            color="success"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'userEditor',
  data: function () {
    return {
      formValid: false,
      role: 'customer',
      firstName: null,
      lastName: null,
      email: null,
      loginName: null,
      password: null,
      customerId: null,
      customerIdErrors: [],
      emailErrors: [],
      loginNameErrors: [],
      phone: null,
      birthday: null,
      showBirthdayMenu: false,
      loading: false
    }
  },
  mounted: function () {
    this.adapt()
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('users', {
      'users': 'list',
      'getUser': 'get'
    }),
    computedRoles: function () {
      const roles = []
      if (this.$route.params.id !== 'neu') {
        if (
          this.ac.can(this.user.role).updateAny('customer')
            .granted ||
          (this.ac.can(this.user.role).updateOwn('customer')
            .granted &&
            this.user._id === this.computedUser._id)
        ) {
          roles.push({ role: 'customer', text: 'Leser|n' })
        }
        if (
          this.ac.can(this.user.role).updateAny('employee')
            .granted ||
          (this.ac.can(this.user.role).updateOwn('employee')
            .granted &&
            this.user._id === this.computedUser._id)
        ) {
          roles.push({ role: 'employee', text: 'Mitarbeiter|n' })
        }
        if (
          this.ac.can(this.user.role).updateAny('admin')
            .granted ||
          (this.ac.can(this.user.role).updateOwn('admin')
            .granted &&
            this.user._id === this.computedUser._id)
        ) {
          roles.push({ role: 'admin', text: 'Administrator|n' })
        }
      } else {
        if (
          this.ac.can(this.user.role).updateAny('customer').granted
        ) {
          roles.push({ role: 'customer', text: 'Leser|n' })
        }
        if (
          this.ac.can(this.user.role).updateAny('employee').granted
        ) {
          roles.push({ role: 'employee', text: 'Mitarbeiter|n' })
        }
        if (
          this.ac.can(this.user.role).updateAny('admin').granted
        ) {
          roles.push({ role: 'admin', text: 'Administrator|n' })
        }
      }
      return roles
    },
    computedUser () {
      if (this.$route.params.id !== 'neu') {
        return this.getUser(this.$route.params.id)
      } else {
        return undefined
      }
    },
    computedBirthday () {
      return this.birthday ? this.$moment(this.birthday).format('DD.MM.YYYY') : ''
    }
  },
  watch: {
    computedUser () {
      this.adapt()
    },
    showBirthdayMenu (val) {
      val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'))
    },
    customerId () {
      this.customerIdErrors = []
    },
    email () {
      this.emailErrors = []
    },
    loginName () {
      this.loginNameErrors = []
    }
  },
  methods: {
    ...mapActions('users', {
      'createUser': 'create',
      'patchUser': 'patch'
    }),
    adapt () {
      if (this.computedUser) {
        this.firstName = this.computedUser.firstName
        this.lastName = this.computedUser.lastName
        this.loginName = this.computedUser.loginName
        this.email = this.computedUser.email
        this.customerId = this.computedUser.customerId
        this.role = this.computedUser.role
        this.birthday = this.computedUser.birthday
        this.phone = this.computedUser.phone
      }
    },
    save (date) {
      this.$refs.birthdayMenu.save(date)
    },
    async saveUser () {
      this.loading = true
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        loginName: this.loginName,
        email: this.email
      }

      if (this.password) {
        map.password = this.password
      }

      if (this.customerId && (!this.computedUser || this.customerId !== this.computedUser.customerId)) {
        map.customerId = this.customerId
        if (this.customerId === '') {
          map.customerId = null
        }
      }

      if (this.role && (!this.computedUser || this.role !== this.computedUser.role)) {
        map.role = this.role
      }

      if (this.birthday) {
        map.birthday = this.$moment(this.birthday).toISOString()
      }

      if (this.phone) {
        map.phone = this.phone
      }

      try {
        if (this.$route.params.id === 'neu') {
          await this.createUser(map)
        } else {
          await this.patchUser([this.computedUser._id, map, {}])
        }
        this.$router.push({ path: '/intern/nutzer', query: this.$router.options.tmpQuery })
      } catch (e) {
        if (e.className === 'conflict') {
          if (e.errors.customerId) {
            this.customerIdErrors.push('Diese Ausweis-Nummer existiert bereits.')
          } else if (e.errors.loginName) {
            this.loginNameErrors.push('Dieser Benutzername existiert bereits.')
          }
          this.loading = false
        }
      }
    },
    async archiveUser () {
      await this.patchUser([this.computedUser._id, { isActive: false }, {}])
      this.$router.push({ path: '/intern/nutzer', query: this.$router.options.tmpQuery })
    },
    async activateUser () {
      await this.patchUser([this.computedUser._id, { isActive: true }, {}])
      this.$router.push({ path: '/intern/nutzer', query: this.$router.options.tmpQuery })
    }
  }
}
</script>
