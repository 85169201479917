<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
              align-self="center"
              class="display-3"
            >
              MITMACHEN
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5" color="rgba(151, 191, 13, 0.2)">
          <v-card-text
            class="black--text"
          >
            <p>
            Liebe Leseratten und Bücherwürmer,
            </p>
            <p>
            du möchtest Teil von Deutschlands größter Leser*innen-Jury sein und die besten neuen Bücher lesen? Du bist zwischen 10 und 14 Jahren alt? Dann melde dich jetzt an und werde Teil der "Ulmer Unke"!
            </p>
            <p>
            Der Winterschlaf ist vorbei und wir freuen uns auf die nächste Lesezeit. Gemeinsam mit unserer Partner-Buchhandlung - dieses Jahr "Hugendubel" - haben wir wieder viele tolle Titel für euch ausgesucht - seid gespannt! Bei "Hugendubel" bekommt ihr dann auch jeden Freitag neuen Lesestoff. Deshalb: Jetzt schnell anmelden und dabei sein!
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    })
  }
}
</script>
