<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
              align-self="center"
              class="display-3"
            >
              DIE ULMER UNKE
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5">
          <v-card-text>
            <P>
              Die Ulmer Unke ist ein Kinder- und Jugendbuchpreis. Seit 2005 lesen und bewerten Bücherwürmer zwischen 10 und 14 Jahren ausgewählte Neuerscheinungen – jeder mindestens sechs Stück von April bis Oktober.
            </P>
            <p>
              Jedes Lesekind bewertet alle Bücher mit 1 bis 10 Punkten und schreibt dazu eine kurze Begründung, sowie ein paar Sätze zum Inhalt.
            </p>
            <p>
              Die Bücher hält im Bewertungszeitraum jedes Jahr eine Partner-Buchhandlung in Ulm an einem festen Wochentag und Uhrzeit zur Ausleihe bereit. Abholtermin ist ab dem 22. März jeden Freitag von 14.00 Uhr bis 18.00 Uhr.
            </p>
            <p>
              Zum Lesen hat jedes Jury-Mitglied drei Wochen Zeit. Erst wenn ein Buch fertig ist, kommt das nächste dran.
            </p>
            <p>
              Die Teilnahme in der Lesejury ist völlig kostenlos!
            </P>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    })
  }
}
</script>
