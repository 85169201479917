<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            class="display-3"
          >
            SIEGERTITEL
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="winner in computedWinners"
        :key="winner._id"
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-2">
                {{winner.year}}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" class="title">
                    Altersgruppe 10-12:
                  </v-col>
                  <v-col cols="12">
                    <img width="100%" :src="computedServerUrl + '/pics/winners/' + winner.pic1012">
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" class="title">
                    Altersgruppe 13+:
                  </v-col>
                  <v-col cols="12">
                    <img width="100%" :src="computedServerUrl + '/pics/winners/' + winner.pic13plus">
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="winner.pdf">
              <v-col>
                <v-btn
                  large
                  tile
                  color="info"
                  block
                  download
                  target="_blank"
                  :href="computedServerUrl + '/pics/winners/' + winner.pdf"
                >
                  <v-icon class="mr-3">far fa-file-pdf</v-icon>
                  Die Top 10 beider Altersgruppen als PDF herunterladen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'winnersList',
  data () {
    return {
    }
  },
  computed: {
    computedServerUrl () {
      return process.env.VUE_APP_SERVER_URL
    },
    ...mapGetters('winners', {
      'winners': 'list'
    }),
    computedWinners () {
      return this.sortByString(this.winners, 'year').reverse()
    }
  },
  methods: {
  }
}
</script>
