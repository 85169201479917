<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            DAS TEAM
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5">
          <v-card-text>
            <v-row>
              <v-col>
                Unser Team besteht aus mehreren Jugendlichen und Kathrin Köhn vom Stadtjugendring. Unsere Aufgaben sind:
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ul>
                  <li>Bücher aus Katalogen für Neuerscheinungen auswählen</li>
                  <li>Ausleihe freitags von März bis Oktober</li>
                  <li>Leseausweise erstellen und ausgeben</li>
                  <li>Preisverleihung vorbereiten und durchführen</li>
                  <li>Die Lesenacht vorbereiten und durchführen</li>
                </ul>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Wenn ihr Zeit und Lust habt die Ulmer Unke mit zu organisieren, dann meldet euch bei:
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-alert
                  color="secondary"
                  dark
                  outlined
                >
                  <div class="body-2 font-weight-bold">Kathrin Köhn</div>
                  <div class="body-2">Telefon: 0731-140 69 17</div>
                  <div class="body-2">Email: koehn(at)sjr-ulm.de</div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Wir freuen uns auf jeden von euch 🙂
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    })
  }
}
</script>
