<template>
  <div>
    <!-- Book info -->
    <v-row>
      <v-col
        align-self="center"
        class="display-3"
        cols="12"
      >
        {{computedBook.baseTitle}} {{computedBook.copyTitle}}
        <v-btn
          class="ml-3 mb-2"
          icon
          color="primary"
          v-if="ac.can(user.role).updateAny('book').granted"
          @click="$router.push('/intern/buecher/editor/' + computedBook._id)"
        >
          <v-icon>
            fa fa-edit
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        align-self="center"
        class="title"
        cols="12"
      >
        von {{computedBook.author}}
      </v-col>
    </v-row>
    <v-row dense class="mb-3">
      <v-col
        cols="12"
        align-self="center"
      >
        ISBN {{computedBook.isbn}}
      </v-col>
    </v-row>
    <!-- Rent -->
    <v-row
      v-if="!computedBook.rental"
    >
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-1">
                Verleihen
              </v-col>
            </v-row>
            <v-row>
              <v-col
                align-self="center"
                cols="12"
                sm="8"
              >
                <v-autocomplete
                  v-model="selectedCustomer"
                  :items="computedUsers"
                  item-value="_id"
                  label="Leser|n auswählen"
                  :filter="$filterFullName"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                align-self="center"
                class="text-right"
                cols="12"
                sm="4"
              >
                <v-btn
                  :disabled="
                    !selectedCustomer ||
                    !(
                      ac.can(user.role).createAny('rental').granted ||
                      (
                        ac.can(user.role).createOwn('rental').granted &&
                        user._id === selectedCustomer._id
                      )
                    )
                  "
                  @click="saveRental()"
                  color="success"
                  block
                >
                  <v-icon class="mr-3">fa fa-book</v-icon>
                  Verleihen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Current rental -->
    <v-row
      v-if="computedBook.rental"
    >
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-1">
                Aktuell verliehen
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                class="title"
                v-if="computedBook.rental.customer"
              >
                Ausgeliehen von {{getUser(computedBook.rental.customer).firstName}} {{getUser(computedBook.rental.customer).lastName}}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                Verliehen am {{$moment(computedBook.rental.createdAt).format('D.M.YYYY, HH:mm')}} Uhr
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <template v-if="$moment(computedBook.rental.createdAt).add(settings[0].defaultRentalDays, 'days').add(computedBook.rental.extends.length, 'weeks').isBefore($moment(settings[0].rentalEnd).endOf('day'), 'day')">
                  Fällig am {{$moment(computedBook.rental.createdAt).startOf('day').add(settings[0].defaultRentalDays + (computedBook.rental.extends.length * 7), 'days').format('dddd, D.M.YYYY')}}
                </template>
                <template v-else>
                  Fällig am {{$moment(settings[0].rentalEnd).format('dddd, D.M.YYYY')}} (Ende der Lesesaison)
                </template>
              </v-col>
            </v-row>
            <template
              v-if="
                $moment().isAfter(
                  $moment(computedBook.rental.createdAt).endOf('day').add(settings[0].defaultRentalDays + (computedBook.rental.extends.length * 7), 'days')
                )
              "
            >
              <v-row dense>
                <v-col
                  class="error--text"
                >
                  Rückgabe ist um {{
                    Math.ceil(computedCalcedDays / 7) }} Woche{{ computedCalcedDays > 1 ? 'n': '' }} verspätet ({{computedCalcedDays}} Tag{{computedCalcedDays > 1 ? 'e' : ''}})
                  <v-icon
                    class="ml-3"
                    color="error"
                    small
                  >
                    fa fa-exclamation-triangle
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template
              v-if="computedBook.rental.extends.length > 0"
            >
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  Bereits verlängert am:
                </v-col>
              </v-row>
              <v-row
                dense
              >
                <v-col>
                  <div
                    :class="extend.late ? 'error--text' : ''"
                    v-for="(extend, i) in computedBook.rental.extends"
                    :key="i"
                  >
                    {{ $moment(extend.dt).format('DD.MM.YYYY') }}
                    <v-icon
                      v-if="extend.late"
                      x-small
                      color="error"
                      class="ml-1 mb-1"
                    >
                      fas fa-exclamation-triangle
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template
              v-if="
                $moment().isAfter(
                  $moment(computedBook.rental.createdAt).endOf('day').add(settings[0].defaultRentalDays + (computedBook.rental.extends.length * 7), 'days')
                ) ||
                computedBook.rental.extends.filter(obj => obj.late).length > 0
              "
            >
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  Mahngebühren:
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <template v-if="computedBook.rental.payed">
                    Bezahlt ({{ (parseInt(Math.ceil(computedCalcedDays / 7)) > 0 ? parseInt(Math.ceil(computedCalcedDays / 7)) : 0) + computedBook.rental.extends.filter(obj => obj.late).length }} €)
                  </template>
                  <template v-else>
                    <span
                      class="error--text"
                    >
                      Betrag offen ({{ (parseInt(Math.ceil(computedCalcedDays / 7)) > 0 ? parseInt(Math.ceil(computedCalcedDays / 7)) : 0) + computedBook.rental.extends.filter(obj => obj.late).length }} €)
                    </span>
                  </template>
                  <v-btn
                    class="ml-3"
                    v-if="!computedBook.rental.payed"
                    outlined
                    color="error"
                    @click="payRental(true)"
                  >
                    <v-icon
                      small
                      class="mr-3"
                    >
                      fas fa-coins
                    </v-icon>
                    Als bezahlt markieren
                  </v-btn>
                  <v-btn
                    class="ml-3"
                    v-else
                    outlined
                    color="error"
                    @click="payRental(false)"
                  >
                    <v-icon
                      small
                      class="mr-3"
                    >
                      fas fa-coins
                    </v-icon>
                    Als offen markieren
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions
            class="pa-4 py-6"
          >
            <template
              v-if="((computedBook.rental && computedBook.rental.extends) ? computedBook.rental.extends.length : 0) < settings[0].maxExtends && $moment(computedBook.rental.createdAt).add(settings[0].defaultRentalDays, 'days').add(computedBook.rental.extends.length, 'weeks').isBefore($moment(settings[0].rentalEnd).startOf('day'))"
            >
              <v-btn
                v-if="
                  ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                  ac.can(user.role).updateAny('rental').attributes.includes('extends') ||
                  (
                    user._id === computedBook.rental.customer &&
                    (
                      ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                      ac.can(user.role).updateOwn('rental').attributes.includes('extends')
                    )
                  )
                "
                @click="extendRental()"
                color="warning"
              >
                <v-icon class="mr-3">fa fa-clock</v-icon>
                Verlängern (noch {{settings[0].maxExtends - ((computedBook.rental && computedBook.rental.extends) ? computedBook.rental.extends.length : 0)}}x möglich)
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              @click="endRental()"
              color="success"
              v-if="
                ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                ac.can(user.role).updateAny('rental').attributes.includes('returned') ||
                (
                  user._id === computedBook.rental.customer &&
                  (
                    ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                    ac.can(user.role).updateOwn('rental').attributes.includes('returned')
                  )
                )
              "
            >
              <v-icon class="mr-3">fa fa-undo</v-icon>
              Zurück geben
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-1">
                Verlauf
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :headers="headers"
            :items="rentals.filter(obj => obj.returned)"
            :server-items-length="computedTotal"
            :search="search"
            :options.sync="options"
            :must-sort="true"
            :loading="loading"
            class="multiline"
          >
            <template
              v-slot:item.createdAt="{ item }"
            >
              {{ $moment(item.createdAt).format('DD.MM.YYYY') }}
            </template>
            <template
              v-slot:item.customer="{ item }"
            >
              {{ getUser(item.customer).firstName }} {{ getUser(item.customer).lastName }}
            </template>
            <template
              v-slot:item.returned="{ item }"
            >
              {{ item.returned ? $moment(item.returned).format('DD.MM.YYYY') : '-' }}
            </template>
            <template
              v-slot:item.extends="{ item }"
            >
              <template
                v-if="item.extends.length > 0"
              >
                <v-row
                  v-for="(extend, i) in item.extends"
                  :key="i"
                >
                  <v-col
                    :class="extend.late ? 'error--text py-0' : 'py-0'"
                  >
                    {{
                      $moment(extend.date).format('DD.MM.YYYY')
                    }}
                  </v-col>
                </v-row>
              </template>
              <template
                v-else
              >
                -
              </template>
            </template>
            <template
              v-slot:item.delayed="{ item }"
            >
              {{
                calcedDays(item) > 0 ?
                Math.ceil(calcedDays(item) / 7) + (Math.ceil(calcedDays(item) / 7) > 1 ? ' Wochen' : ' Woche') : '-'
              }}
            </template>
            <template
              v-slot:item.payed="{ item }"
            >
              <template
                v-if="calcedDays(item) > 0 || item.extends.filter(obj => obj.late).length > 0"
              >
                <template
                  v-if="item.payed"
                >
                  Bezahlt ({{ (calcedDays(item) > 0 ? Math.ceil(calcedDays(item) / 7) : 0) + item.extends.filter(obj => obj.late).length }} €)
                  <v-btn
                    class="ml-3"
                    small
                    outlined
                    icon
                    color="error"
                    @click="payRental(false, item._id)"
                  >
                    <v-icon
                      small
                    >
                      fas fa-times
                    </v-icon>
                  </v-btn>
                </template>
                <template
                  v-else
                >
                  Offen&nbsp;
                  ({{ (calcedDays(item) > 0 ? Math.ceil(calcedDays(item) / 7) : 0) + item.extends.filter(obj => obj.late).length }} €)
                  <v-btn
                    class="ml-3"
                    small
                    outlined
                    icon
                    color="error"
                    @click="payRental(true, item._id)"
                  >
                    <v-icon
                      small
                    >
                      fas fa-coins
                    </v-icon>
                  </v-btn>
                </template>
              </template>
              <template
                v-else
              >
                -
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'

export default {
  name: 'book',
  mixins: [makeFindMixin({ service: 'rentals', watch: true })],
  data: function () {
    return {
      selectedCustomer: null,
      search: '',
      headers: [
        { text: 'Ausgeliehen', value: 'createdAt' },
        { text: 'Leser|n', value: 'customer' },
        { text: 'Zurück am', value: 'returned' },
        { text: 'Verlängert am', value: 'extends' },
        { text: 'Verspätet', value: 'delayed' },
        { text: 'Bezahlt', value: 'payed' }
      ],
      totalRentals: 0,
      loading: true,
      options: { sortBy: ['createdAt'], sortDesc: [true] },
      skip: 0,
      limit: 10,
      sortBy: 'createdAt',
      sortDesc: 1
    }
  },
  watch: {
    rentals: {
      handler () {
        this.loading = false
      },
      deep: true
    },
    options: {
      handler () {
        this.loading = true
        this.skip = (this.options.page - 1) * this.options.itemsPerPage
        this.limit = this.options.itemsPerPage
        this.sortBy = this.options.sortBy[0]
        if (this.options.sortDesc !== undefined) {
          this.sortDesc = this.options.sortDesc[0] === true ? -1 : 1
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('books', {
      'getBook': 'get'
    }),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('users', {
      'users': 'list',
      'getUser': 'get'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    }),
    rentalsParams () {
      return { query: { book: (this.computedBook._id ? this.computedBook._id : null), $limit: this.limit, $skip: this.skip, $sort: { [this['sortBy']]: this.sortDesc } } }
    },
    computedTotal () {
      try {
        return this.rentalsPaginationData.default.mostRecent.total
      } catch (e) {
        return 0
      }
    },
    computedBook () {
      return this.getBook(this.$route.params.id)
    },
    computedUsers: function () {
      return this.sortByString(this.users.filter(
        obj => obj.customerId && obj.customerId !== '' && obj.isActive
      ), 'firstName')
    },
    computedCalcedDays () {
      return this.$moment().endOf('day').diff(
        this.$moment(
          this.computedBook.rental.createdAt).endOf('day').add(this.settings[0].defaultRentalDays + (this.computedBook.rental.extends.length * 7), 'days'
        ),
        'days'
      )
    }
  },
  methods: {
    ...mapActions('rentals', {
      'createRental': 'create',
      'patchRental': 'patch'
    }),
    ...mapActions('books', {
      'patchBook': 'patch'
    }),
    async saveRental () {
      const map = {
        customer: this.selectedCustomer,
        book: this.$route.params.id
      }
      const rental = await this.createRental(map)
      await this.patchBook([this.$route.params.id, { rental: rental._id }, { query: { $populate: ['rental', 'votes'] } }])
      this.selectedCustomer = null
    },
    payRental (value, id = this.computedBook.rental._id) {
      this.patchRental([id, { payed: value }, {}])
    },
    async endRental () {
      await this.patchRental([this.computedBook.rental._id, { returned: Date.now() }, {}])
      await this.patchBook([this.$route.params.id, { rental: null }, { query: { $populate: ['rental', 'votes'] } }])
      this.findRentals()
    },
    extendRental () {
      const tmpExtends = JSON.parse(JSON.stringify(this.computedBook.rental.extends))
      const tmpLate = Math.ceil(this.computedCalcedDays / 7) > 0
      tmpExtends.push({
        dt: new Date(),
        late: tmpLate
      })
      this.patchRental([this.computedBook.rental._id, { extends: tmpExtends }, {}])
    },
    calcedDays (rental) {
      return parseInt(this.$moment(rental.returned).endOf('day')
        .diff(
          this.$moment(rental.createdAt).endOf('day')
            .add(this.settings[0].defaultRentalDays + (rental.extends.length * 7), 'days'),
          'days'
        )
      )
    }
  }
}
</script>
