<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            BEWERTUNGEN
          </v-col>
          <v-col
            :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'"
            align-self="center"
            v-if="$moment().isBefore($moment(settings[0].votingEnd).endOf('day'))"
          >
            <v-btn
              class="mr-0"
              color="success"
              v-if="
                ac.can(user.role).createOwn('vote').granted
              "
              @click="$router.push('/intern/bewertungen/editor/neu')"
            >
              <v-icon
                class="mr-3"
                small
              >
                fa fa-plus
              </v-icon>
              Neue Bewertung abgeben
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="title">
            Bewertungen können noch bis einschließlich <span class="font-weight-bold">{{ $moment(settings[0].votingEnd).format('dddd, DD.MM.YYYY') }}</span> abgegeben oder bearbeitet werden.
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-checkbox
          v-if="
            ac &&
            ac.can(user.role).updateAny('vote').granted
          "
          color="primary"
          v-model="showAllVotes"
          label="Alle Bewertungen ansehen"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            @update:page="updatePage"
            @update:items-per-page="updateItemsPerPage"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            :headers="headers"
            :items="votes"
            :server-items-length="total"
            :search="search"
            must-sort
            :loading="loading"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template
              v-slot:item.createdAt="{ item }"
            >
              {{ $moment(item.createdAt).format('DD.MM.YYYY') }}
            </template>
            <template
              v-slot:item.customerName="{ item }"
            >
              {{item.customerName}}
            </template>
            <template
              v-slot:item.bookTitle="{ item }"
            >
              {{item.bookTitle}}
            </template>
            <template
              v-slot:item.value="{ item }"
            >
              <v-rating
                :value="item.value"
                x-small
                length="10"
                half-increments
                dense
                readonly
                color="info"
                background-color="info"
              >
              </v-rating>
            </template>
            <template
              v-slot:item.edit="{ item }"
            >
              <v-btn
                icon
                color="primary"
                @click="$router.push('/intern/bewertungen/editor/' + item._id)"
                :disabled="!$moment().isBefore($moment(settings[0].votingEnd).endOf('day'))"
              >
                <v-icon small>{{ $moment().isBefore($moment(settings[0].votingEnd).endOf('day')) ? 'fas fa-pen' : 'fas fa-ban' }}</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="
        ac.can(user.role).readAny('vote').granted &&
        ac.can(user.role).readAny('vote').attributes.includes('*')
      "
    >
      <v-col class="text-right">
        <v-btn
          @click="getCsv()"
          outlined
          color="primary"
        >
          Bewertungen herunterladen
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import feathersClient from '@/feathers-client'

export default {
  name: 'votes',
  data () {
    return {
      headers: [
        { text: 'Abgegeben am', value: 'createdAt' },
        { text: 'Titel', value: 'bookTitle' },
        { text: 'Bewertung', value: 'value' },
        { text: 'Bearbeiten', value: 'edit', sortable: false }
      ],
      totalRentals: 0,
      loading: true,
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [ false ],
      search: '',
      showAllVotes: false,
      csvService: undefined,
      votes: [],
      total: 0
    }
  },
  mounted () {
    // Save current query
    this.$router.options.tmpQuery = this.$route.query
    // Init csv service
    this.csvService = feathersClient.service('csv')
    // Set show all
    if (
      this.ac &&
      this.ac.can(this.user.role).updateAny('vote').granted &&
      localStorage.getItem('votesShowAll')
    ) {
      if (localStorage.getItem('votesShowAll') === 'true') {
        this.showAllVotes = true
      } else {
        this.showAllVotes = false
      }
    } else {
      localStorage.setItem('votesShowAll', this.showAllVotes)
    }
    // Process query
    if (this.$route.query.i) {
      this.itemsPerPage = parseInt(this.$route.query.i)
    }
    if (this.$route.query.p) {
      this.page = parseInt(this.$route.query.p)
    }
    if (this.$route.query.s) {
      this.sortBy = this.$route.query.s.split(',')
    }
    if (this.$route.query.d) {
      let tmpDesc = this.$route.query.d.split(',')
      for (let i = 0; i < tmpDesc.length; i++) {
        if (tmpDesc[i] === 'true') {
          tmpDesc[i] = true
        } else if (this.$route.query.d === 'false') {
          tmpDesc[i] = false
        }
      }
      this.sortDesc = tmpDesc
    }
    // Init votes
    this.findVotes(this.computedVotesParams)
      .then((result) => {
        this.votes = result.data
        this.total = result.total
      })
  },
  methods: {
    ...mapActions('votes', {
      findVotes: 'find'
    }),
    async getCsv () {
      const sheet = await this.csvService.create({ type: 'votes' })
      const blob = new Blob(
        [ sheet ],
        { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
      )
      this.downloadBlob(blob, 'UnkeBewertungen_' + this.$moment().format('YYYY-MM-DD_hh-mm') + '.xlsx')
    },
    downloadBlob (blob, filename) {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename || 'download'
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          a.removeEventListener('click', clickHandler)
        }, 150)
      }
      a.addEventListener('click', clickHandler, false)
      a.click()
    },
    updatePage (data) {
      if (parseInt(this.$route.query.p) !== data) {
        this.$router.replace({ query: {
          p: data,
          i: this.itemsPerPage,
          s: this.sortBy.join(','),
          d: this.sortDesc.join(',')
        } })
      }
    },
    updateItemsPerPage (data) {
      if (parseInt(this.$route.query.i) !== data) {
        this.$router.replace({ query: {
          p: this.page,
          i: data,
          s: this.sortBy.join(','),
          d: this.sortDesc.join(',')
        } })
      }
    },
    updateSortBy (data) {
      let tmpData
      if (Array.isArray(data)) {
        tmpData = data.join(',')
      } else {
        tmpData = data
      }
      if (data && this.$route.query.s !== tmpData) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          s: tmpData,
          d: this.sortDesc.join(',')
        } })
      } else if (!data) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          d: this.sortDesc.join(',')
        } })
      }
    },
    updateSortDesc (data) {
      let tmpData
      if (Array.isArray(data)) {
        tmpData = data.join(',')
      } else {
        tmpData = data
      }
      if (data && this.$route.query.d !== tmpData) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          s: this.sortBy.join(','),
          d: tmpData
        } })
      }
    }
  },
  watch: {
    computedVotesParams () {
      this.findVotes(this.computedVotesParams)
        .then((result) => {
          this.votes = result.data
          this.total = result.total
        })
    },
    votes () {
      this.loading = false
    },
    showAllVotes () {
      if (this.showAllVotes) {
        this.headers.splice(1, 0, { text: 'Leser|n', value: 'customerName' })
      } else {
        this.headers.splice(1, 1)
      }
      localStorage.setItem('votesShowAll', this.showAllVotes)
    },
    '$route.query' () {
      this.$router.options.tmpQuery = this.$route.query
    }
  },
  computed: {
    computedVotesParams () {
      if (this.showAllVotes) {
        return { query: { $limit: this.computedLimit, $skip: (this.page - 1) * this.computedSkip, $sort: { [this['sortBy']]: this.computedSortDesc } } }
      } else {
        return { query: { customer: (this.user._id ? this.user._id : null), $limit: this.computedLimit, $skip: (this.page - 1) * this.computedSkip, $sort: { [this['sortBy']]: this.computedSortDesc } } }
      }
    },
    computedLimit () {
      if (this.itemsPerPage === -1) {
        return 1000000
      } else {
        return this.itemsPerPage
      }
    },
    computedSkip () {
      if (this.itemsPerPage === -1) {
        return 0
      } else {
        return this.itemsPerPage
      }
    },
    computedSortDesc () {
      if (this.sortDesc[0] === true) {
        return 1
      } else {
        return -1
      }
    },
    votesParams () {
      return this.computedVotesParams
    },
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('books', {
      'getBook': 'get'
    }),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('users', {
      'getUser': 'get'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    }),
    ...mapGetters('votes', {
      'listVotes': 'list'
    })
  }
}
</script>
