<template>
  <div>
    <v-row>
        <v-col>
          <v-row>
            <v-col
              align-self="center"
              class="display-3"
            >
              SIEGERTITEL EDITOR
            </v-col>
          </v-row>
        </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form
              v-model="formValid"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    label="Jahr*"
                    type="number"
                    v-model="year"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('winner').attributes.includes('*') ||
                        ac.can(user.role).updateAny('winner').attributes.includes('year')
                      )
                    "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-if="pdf"
                    v-model="pdf"
                    readonly
                    append-icon="fas fa-trash"
                    @click:append="removePdf"
                  ></v-text-field>
                  <vue-dropzone
                    v-else
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    :headers="dropzoneOptions.headers"
                    @vdropzone-success="uploadSuccessHandler"
                    @vdropzone-sending="sendingHandler"
                    :useCustomSlot="true"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">Broschüre als PDF hier ablegen</h3>
                      <div class="subtitle">Oder klicken um eine Datei auszuwählen.</div>
                    </div>
                  </vue-dropzone>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="body-1">
                  Altersgruppe 10-12 (max. 5MB)*<br>
                  <span v-if="!pic1012 && !pic1012ToUpload" class="body-2 error--text">{{ upload1012Error ? upload1012Error : 'Pflichteingabe.' }}.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="pic1012"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-img
                    :aspect-ratio="3/4"
                    :src="computedServerUrl + '/pics/winners/' + pic1012"
                  >
                    <v-container class="fill-height">
                      <v-row class="ma-0">
                        <v-col class="text-center">
                          <v-btn
                            fab
                            color="primary"
                            depressed
                            @click="removePic1012()"
                          >
                            <v-icon small>fas fa-trash</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-col>
                <!-- Tmp images -->
                <v-col
                  v-if="pic1012ToUpload"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-img
                    :aspect-ratio="3/4"
                    :src="pic1012ToUpload"
                  >
                    <v-container class="fill-height">
                      <v-row class="ma-0">
                        <v-col class="text-center">
                          <v-hover v-slot:default="{ hover }">
                            <v-btn
                              fab
                              :color="hover ? 'primary' : 'info'"
                              depressed
                              @click="removePic1012ToUpload()"
                            >
                              <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  v-if="!pic1012 && !pic1012ToUpload"
                >
                  <label for="pic1012FileInput">
                    <v-img
                      class="text-center fill-height clickable"
                      :aspect-ratio="3/4"
                    >
                      <v-container class="add-more" fill-height>
                        <v-row class="ma-0">
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-col>
                              <input
                                v-on:change="onPic1012FileChange"
                                style="height: 0px; width: 0px; opcity: 0;"
                                type="file"
                                id="pic1012FileInput"
                                name="image"
                                accept="image/png, image/jpeg"
                              />
                              <v-icon
                                :color="hover ? 'primary' : 'info'"
                              >
                                fa fa-image
                              </v-icon>
                              <div :class="hover ? 'primary--text' : 'info--text'">+   Bild hinzufügen</div>
                            </v-col>
                          </v-hover>
                        </v-row>
                      </v-container>
                    </v-img>
                  </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="body-1">
                  Altersgruppe 13+ (max. 5MB)*<br>
                  <span v-if="!pic13plus && !pic13plusToUpload" class="body-2 error--text">{{ upload13plusError ? upload13plusError : 'Pflichteingabe.' }}.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="pic13plus"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-img
                    :aspect-ratio="3/4"
                    :src="computedServerUrl + '/pics/winners/' + pic13plus"
                  >
                    <v-container class="fill-height">
                      <v-row class="ma-0">
                        <v-col class="text-center">
                          <v-btn
                            fab
                            color="primary"
                            depressed
                            @click="removePic13plus()"
                          >
                            <v-icon small>fas fa-trash</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-col>
                <!-- Tmp images -->
                <v-col
                  v-if="pic13plusToUpload"
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-img
                    :aspect-ratio="3/4"
                    :src="pic13plusToUpload"
                  >
                    <v-container class="fill-height">
                      <v-row class="ma-0">
                        <v-col class="text-center">
                          <v-hover v-slot:default="{ hover }">
                            <v-btn
                              fab
                              :color="hover ? 'primary' : 'info'"
                              depressed
                              @click="removePic13plusToUpload()"
                            >
                              <v-icon small>fas fa-trash</v-icon>
                            </v-btn>
                          </v-hover>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                  v-if="!pic13plus && !pic13plusToUpload"
                >
                  <label for="pic13plusFileInput">
                    <v-img
                      class="text-center fill-height clickable"
                      :aspect-ratio="3/4"
                    >
                      <v-container class="add-more" fill-height>
                        <v-row class="ma-0">
                          <v-hover
                            v-slot:default="{ hover }"
                          >
                            <v-col>
                              <input
                                v-on:change="onPic13plusFileChange"
                                style="height: 0px; width: 0px; opcity: 0;"
                                type="file"
                                id="pic13plusFileInput"
                                name="image"
                                accept="image/png, image/jpeg"
                              />
                              <v-icon
                                :color="hover ? 'primary' : 'info'"
                              >
                                fa fa-image
                              </v-icon>
                              <div :class="hover ? 'primary--text' : 'info--text'">+   Bild hinzufügen</div>
                            </v-col>
                          </v-hover>
                        </v-row>
                      </v-container>
                    </v-img>
                  </label>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 py-6">
            <template v-if="$route.params.id !== 'neu'">
              <v-btn
                @click="prepareRemoveWinners()"
                color="error"
              >
                Löschen
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!formValid || (!pic1012 && !pic1012ToUpload) ||  (!pic13plus && !pic13plusToUpload) || loading || upload13plusError"
              @click="saveWinner()"
              color="success"
              :loading="loading"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import feathersClient from '@/feathers-client'
import { mapGetters, mapActions } from 'vuex'
import Jimp from 'jimp'

export default {
  name: 'winnersEditor',
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function () {
    return {
      map: {},
      uploadService: undefined,
      formValid: false,
      year: null,
      pic1012: null,
      pic13plus: null,
      pdf: null,
      pic1012ToUpload: null,
      pic13plusToUpload: null,
      pdfToUpload: null,
      loading: false,
      upload1012Error: null,
      upload13plusError: null,
      dropzoneOptions: {
        paramName: 'uri',
        timeout: 600000,
        autoProcessQueue: false,
        acceptedFiles: 'application/pdf',
        uploadMultiple: false,
        headers: {
          'Authorization': localStorage.getItem('feathers-jwt')
        },
        url: 'https://unke.widerstand.digital/uploads',
        init: function () {
        },
        error: function (file, response) {}
      }
    }
  },
  mounted: function () {
    this.adapt()
    this.uploadService = feathersClient.service('uploads')
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('winners', {
      'getWinner': 'get'
    }),
    computedWinner () {
      if (this.$route.params.id !== 'neu') {
        return this.getWinner(this.$route.params.id)
      } else {
        return undefined
      }
    },
    computedServerUrl () {
      return process.env.VUE_APP_SERVER_URL
    }
  },
  watch: {
    computedWinner () {
      this.adapt()
    },
    pic1012ToUpload () {
      if (this.pic1012ToUpload) {
        if (((this.pic1012ToUpload.length * (3 / 4)) / 1000000) > 5) {
          this.pic1012ToUpload = null
          this.upload1012Error = 'Maximale Größe von 5 MB überschritten.'
        }
      }
    },
    pic13plusToUpload () {
      if (this.pic13plusToUpload) {
        if (((this.pic13plusToUpload.length * (3 / 4)) / 1000000) > 5) {
          this.pic13plusToUpload = null
          this.upload13plusError = 'Maximale Größe von 5 MB überschritten.'
        }
      }
    }
  },
  methods: {
    sendingHandler (file, xhr, formData) {
      formData.append('path', '/winners/')
      formData.append('id', this.getUniqueFileName(file))
    },
    async uploadSuccessHandler (file, response) {
      this.map.pdf = response
      // Send to server
      if (this.$route.params.id === 'neu') {
        await this.createWinner(this.map, {})
      } else {
        await this.patchWinner([this.computedWinner._id, this.map, {}])
      }
      this.$router.push('/intern/siegertitel')
    },
    ...mapActions('winners', {
      'createWinner': 'create',
      'patchWinner': 'patch',
      'removeWinner': 'remove'
    }),
    ...mapActions('uploads', {
      'createUpload': 'create',
      'removeUpload': 'remove'
    }),
    adapt () {
      if (this.computedWinner) {
        this.year = this.computedWinner.year
        this.pic1012 = this.computedWinner.pic1012
        this.pic13plus = this.computedWinner.pic13plus
        this.pdf = this.computedWinner.pdf
      }
    },
    getUniqueFileName (file) {
      let newFileName
      const fileNameSuffix = '_' + this.$moment().format('YYYY-MM-DD_HH-mm')
      let splittedFileName = file.name.split('.')
      if (splittedFileName.length > 1) {
        splittedFileName[splittedFileName.length - 2] += fileNameSuffix
        newFileName = splittedFileName.join('.')
      } else {
        newFileName = splittedFileName + fileNameSuffix
      }
      this.pdfToUpload = newFileName
      return newFileName
    },
    onPic1012FileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.updatePic1012Name(files[0])
    },
    onPic13plusFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) { return }
      this.updatePic13plusName(files[0])
    },
    updatePic1012Name (file) {
      const reader1012 = new FileReader()
      reader1012.onload = (e) => {
        this.pic1012ToUpload = e.target.result
      }
      reader1012.readAsDataURL(file)
    },
    updatePic13plusName (file) {
      const reader13plus = new FileReader()
      reader13plus.onload = (e) => {
        this.pic13plusToUpload = e.target.result
      }
      reader13plus.readAsDataURL(file)
    },
    removePic1012 () {
      this.uploadError = null
      this.pic1012 = null
    },
    removePic13plus () {
      this.uploadError = null
      this.pic13plus = null
    },
    removePic1012ToUpload () {
      this.uploadError = null
      this.pic1012ToUpload = null
    },
    removePic13plusToUpload () {
      this.uploadError = null
      this.pic13plusToUpload = null
    },
    removePdf () {
      this.uploadError = null
      this.pdf = null
    },
    removePdfToUpload () {
      this.uploadError = null
      this.pdfToUpload = null
    },
    async prepareRemoveWinners () {
      await this.uploadService.remove({ fileName: this.computedWinner.pic1012, path: '/winners/' })
      await this.uploadService.remove({ fileName: this.computedWinner.pic13plus, path: '/winners/' })
      if (this.computedWinner.pdf) {
        await this.uploadService.remove({ fileName: this.computedWinner.pdf, path: '/winners/' })
      }
      await this.removeWinner(this.computedWinner._id)
      this.$router.push('/intern/siegertitel')
    },
    async saveWinner () {
      this.loading = true
      // Create new pic path array
      this.map = {
        year: this.year
      }
      // Remove existing pics
      if (this.computedWinner) {
        if (this.computedWinner.pic1012 !== this.pic1012) {
          await this.uploadService.remove({ fileName: this.computedWinner.pic1012, path: '/winners/' })
        }
        if (this.computedWinner.pic13plus !== this.pic13plus) {
          await this.uploadService.remove({ fileName: this.computedWinner.pic13plus, path: '/winners/' })
        }
        if (!this.pdf && this.computedWinner.pdf) {
          await this.uploadService.remove({ fileName: this.computedWinner.pdf, path: '/winners/' })
          this.map.pdf = null
        }
      }
      // Upload new Pics
      if (this.pic1012ToUpload) {
        const uri1012 = await this.processImage(this.pic1012ToUpload)
        const pic1012Name = await this.uploadService.create({ uri: uri1012, path: '/winners/' })
        this.map.pic1012 = pic1012Name
      }
      if (this.pic13plusToUpload) {
        const uri13plus = await this.processImage(this.pic13plusToUpload)
        const pic13plusName = await this.uploadService.create({ uri: uri13plus, path: '/winners/' })
        this.map.pic13plus = pic13plusName
      }
      // Upload new pdfs
      if (this.$refs.myVueDropzone.getQueuedFiles().length > 0) {
        this.$refs.myVueDropzone.processQueue()
      } else {
        // Send to server
        if (this.$route.params.id === 'neu') {
          await this.createWinner(this.map, {})
        } else {
          await this.patchWinner([this.computedWinner._id, this.map, {}])
        }
        this.$router.push('/intern/siegertitel')
      }
    },
    async processImage (file) {
      const size = (file.length * (3 / 4)) / 1000000
      let quality
      const calcedQuality = 80 - (size * 4)
      if (calcedQuality < 50) {
        quality = 50
      } else if (calcedQuality > 100) {
        quality = 100
      } else {
        quality = calcedQuality
      }
      const image = await Jimp.read(Buffer.from(file.split(',')[1], 'base64'))
      if (image.bitmap.width > 720) {
        image.resize(720, Jimp.AUTO)
      }
      if (image.bitmap.height > 720) {
        image.resize(Jimp.AUTO, 720)
      }
      image.quality(quality)
      const finalUri = await image.getBase64Async(image.getMIME())
      return finalUri
    }
  }
}
</script>

<style scoped>
  .add-more {
    border: 2px dashed #b3c1cc !important;
  }
  .add-more:hover {
    border: 2px dashed #79a2c8 !important;
  }
</style>
