<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            SIEGERTITEL|NNEN
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'" align-self="center">
            <v-btn
              class="mr-0"
              color="success"
              v-if="
                ac.can(user.role).createAny('customer').granted
              "
              @click="$router.push('/intern/siegertitel/editor/neu')"
            >
              <v-icon
                class="mr-3"
                small
              >
                fa fa-plus
              </v-icon>
              Neue Siegertitel hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list>
          <v-list-item
            v-for="winner in computedWinners"
            :key="winner._id"
          >
            <v-list-item-content>
              {{winner.year}}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                fab
                x-small
                color="primary"
                class="elevation-2"
                @click="$router.push('/intern/siegertitel/editor/' + winner._id)"
              >
                <v-icon>fas fa-pen</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'winnersList',
  data () {
    return {
    }
  },
  computed: {
    computedCustomers () {
      return this.sortByString(this.winners, 'year')
    },
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('winners', {
      'winners': 'list'
    }),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    computedWinners () {
      return this.sortByString(this.winners, 'year').reverse()
    }
  },
  methods: {
  }
}
</script>
