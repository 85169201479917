<template>
  <div>
    <v-row>
      <v-col>
        <Participate ref="participate"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <About ref="unke"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Jury ref="jury"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Team ref="team"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Participate from '@/components/Participate'
import About from '@/components/About'
import Jury from '@/components/Jury'
import Team from '@/components/Team'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'about',
  components: {
    Participate,
    About,
    Team,
    Jury
  },
  methods: {
    ...mapMutations({
      'setSection': 'SET_SECTION'
    })
  },
  computed: {
    ...mapGetters([
      'section'
    ])
  },
  watch: {
    section (id) {
      if (this.$refs[id]) {
        this.$vuetify.goTo(this.$refs[id].$el, { offset: 10 })
      }
      this.setSection(false)
    }
  }
}
</script>
