<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            BÜCHER
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-left'" align-self="center">
            <v-btn
              class="mr-0"
              color="success"
              v-if="
                ac &&
                ac.can(user.role).createAny('book').granted
              "
              @click="$router.push('/intern/buecher/editor/neu')"
            >
              <v-icon
                class="mr-3"
                small
              >
                fa fa-plus
              </v-icon>
              Neues Buch hinzufügen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Filters -->
    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          label="Suche"
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          v-model="selectedAudience"
          :items="['Alle', '10-12', '13+']"
          label="Altersgruppe"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-checkbox
          v-if="
            ac &&
            ac.can(user.role).updateAny('book').granted
          "
          color="primary"
          v-model="showArchivedBooks"
          label="Archivierte Bücher anzeigen"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          v-if="
            ac &&
            ac.can(user.role).updateAny('book').granted
          "
          color="primary"
          v-model="showDuplicates"
          label="Duplikate anzeigen"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row
      v-if="
        ac &&
        ac.can(user.role).readAny('vote').granted
      "
    >
      <v-col class="mb-8 mt-0">
        <v-btn-toggle
          v-model="viewMode"
        >
          <v-btn value="grid">
            <v-icon>fas fa-grip-horizontal</v-icon>
          </v-btn>
          <v-btn value="list">
            <v-icon>fas fa-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <!-- Books -->
    <template v-if="viewMode === 'grid'">
      <v-row>
        <v-col
          v-for="book in computedBooks"
          :key="book._id"
          cols="12"
          sm="12"
          md="6"
          lg="4"
        >
          <v-hover v-slot:default="{ hover }">
            <v-sheet
              :class="hover ? 'clickable elevation-10' : 'clickable'"
            >
              <v-img
                :src="computedServerUrl + '/pics/books/' + book.pics[0]"
                @click="setActiveBook(book)"
              >
              </v-img>
            </v-sheet>
          </v-hover>
        </v-col>
      </v-row>
      <!-- Detail -->
      <v-dialog
        v-model="showBookDialog"
        max-width="600"
      >
        <v-card>
          <v-card-text v-if="activeBook !== null">
            <v-row>
              <v-col class="text-right">
                <v-btn
                  icon
                  @click="showBookDialog = false"
                >
                  <v-icon>
                    fas fa-times
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="display-3 primary--text">
                {{activeBook.baseTitle}}
                </v-col>
            </v-row>
            <v-row>
              <v-col class="title">
                von {{activeBook.author}}
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="info--text"
                v-if="activeBook.isbn"
              >
                ISBN {{activeBook.isbn}}
              </v-col>
            </v-row>
            <v-row v-if="ac">
              <v-col v-if="showDuplicates">
                {{activeBook.rental ? 'Derzeit verliehen' : 'Derzeit verfügbar'}}
              </v-col>
              <v-col v-else>
                Derzeit {{books.filter(obj => obj.isActive && obj.baseTitle === activeBook.baseTitle && (!obj.rental || obj.rental === 'null')).length}} von {{books.filter(obj => obj.isActive && obj.baseTitle === activeBook.baseTitle).length}} verfügbar.
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                {{activeBook.description}}
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions
            class="pa-4 py-6"
          >
            <template
              v-if="activeBook && activeBook.copyTitle && activeBook.copyTitle !== ''"
            >
              <v-menu
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="
                      ac &&
                      ac.can(user.role).updateAny('book').granted
                    "
                    color="primary"
                    v-on="on"
                  >
                    <v-icon
                      small
                      class="mr-3"
                    >
                      fa fa-chevron-down
                    </v-icon>
                    Bearbeiten
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="copy in books.filter(obj => (obj.isActive || showArchivedBooks) && obj.baseTitle === activeBook.baseTitle)"
                    :key="copy._id"
                    @click="$router.push('/intern/buecher/editor/' + copy._id)"
                  >
                    <v-list-item-title>{{copy.copyTitle}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else>
              <v-btn
                  v-if="
                    ac &&
                    ac.can(user.role).updateAny('book').granted
                  "
                  color="primary"
                  @click="$router.push('/intern/buecher/editor/' + activeBook._id)"
                >
                  <v-icon
                    small
                    class="mr-3"
                  >
                    fa fa-pen
                  </v-icon>
                  Bearbeiten
                </v-btn>
            </template>
            <v-spacer></v-spacer>
            <template
              v-if="activeBook && activeBook.copyTitle && activeBook.copyTitle !== ''"
            >
              <v-menu
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="
                      ac &&
                      ac.can(user.role).createAny('rental').granted
                    "
                    color="primary"
                    v-on="on"
                  >
                    <v-icon
                      small
                      class="mr-3"
                    >
                      fa fa-chevron-down
                    </v-icon>
                    Verleihen
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="copy in books.filter(obj => obj.isActive && obj.baseTitle === activeBook.baseTitle)"
                    :key="copy._id"
                    @click="$router.push('/intern/buecher/' + copy._id)"
                  >
                    <v-list-item-title
                      :class="(copy.rental && copy.rental !== null) ? 'warning--text' : 'primary--text'"
                    >
                      {{copy.copyTitle}}&nbsp;
                      {{(copy.rental && copy.rental !== null) ? '- verliehen' : ''}}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-else>
              <v-btn
                  v-if="
                    ac &&
                    ac.can(user.role).createAny('rental').granted
                  "
                  color="primary"
                  @click="$router.push('/intern/buecher/' + activeBook._id)"
                >
                  <v-icon
                    small
                    class="mr-3"
                  >
                    fa fa-book-reader
                  </v-icon>
                  Verleihen
                </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-else-if="viewMode === 'list'">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="computedBooks"
          multi-sort
          @update:page="updatePage"
          @update:items-per-page="updateItemsPerPage"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template
            v-slot:item.baseTitle="{ item }"
          >
            {{ item.baseTitle }} {{ showDuplicates ? item.copyTitle : ''}}
            <template v-if="showDuplicates">
              <v-btn
                fab
                x-small
                color="primary"
                class="ml-3 elevation-2"
                @click="$router.push('/intern/buecher/editor/' + item._id )"
                v-if="
                  ac &&
                  ac.can(user.role).updateAny('book').granted
                "
              >
                <v-icon
                  x-small
                >
                  fas fa-pen
                </v-icon>
              </v-btn>
            </template>
            <template v-else>
              <v-menu
                  v-if="
                    ac &&
                    ac.can(user.role).updateAny('book').granted
                  "
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      class="ml-3"
                    >
                      <v-icon
                        small
                      >
                        fa fa-pen
                      </v-icon>
                      <v-icon
                        small
                        class="ml-2"
                      >
                        fa fa-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="copy in books.filter(obj => (obj.isActive || showArchivedBooks) && obj.baseTitle === item.baseTitle)"
                      :key="copy._id"
                      @click="$router.push('/intern/buecher/editor/' + copy._id)"
                    >
                      <v-list-item-title
                        class="primary--text"
                      >
                        {{copy.copyTitle}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </template>
          </template>
          <template
            v-slot:item.rating.avg="{ item }"
          >
            <template
              v-if="item.rating && item.rating.avg"
            >
              <v-row no-gutters>
                  <v-rating
                    :value="item.rating.avg"
                    x-small
                    dense
                    length="10"
                    half-increments
                    readonly
                    color="info"
                    background-color="info"
                    class="mr-3"
                  >
                  </v-rating>
                  ({{ (Math.round(item.rating.avg * 100) / 100).toFixed(2) }})
              </v-row>
            </template>
            <template
              v-else
            >
              -
            </template>
          </template>
          <template
            v-slot:item.rating.count="{ item }"
          >
            <template
              v-if="item.rating && item.rating.count"
            >
              {{item.rating.count}}
            </template>
            <template
              v-else
            >
              -
            </template>
          </template>
          <template
            v-slot:item.rental="{ item }"
          >
            <template v-if="showDuplicates">
              <template v-if="item.isActive">
                <span :class="item.rental ? 'warning--text' : 'primary--text'">
                  {{ item.rental ? 'Derzeit verliehen' : 'Derzeit verfügbar' }}
                  <v-btn
                    v-if="
                      ac &&
                      ac.can(user.role).createAny('rental').granted
                    "
                    color="primary"
                    x-small
                    fab
                    class="elevation-2 ml-3"
                    @click="$router.push('/intern/buecher/' + item._id)"
                  >
                    <v-icon
                      small
                    >fas fa-book-reader</v-icon>
                  </v-btn>
                </span>
              </template>
              <template v-else>
                Archiviert
              </template>
            </template>
            <template v-else>
              {{books.filter(obj => obj.isActive && obj.baseTitle === item.baseTitle && (!obj.rental || obj.rental === 'null')).length}} / {{books.filter(obj => obj.isActive && obj.baseTitle === item.baseTitle).length}}
              <v-menu
                  v-if="
                    item.copyTitle && item.copyTitle !== '' &&
                    books.filter(obj => obj.isActive && obj.baseTitle === item.baseTitle && (!obj.rental || obj.rental === 'null')).length > 0 &&
                    ac &&
                    ac.can(user.role).createAny('rental').granted
                  "
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      color="primary"
                      v-on="on"
                      class="ml-3"
                    >
                      <v-icon
                        small
                      >
                        fa fa-book-reader
                      </v-icon>
                      <v-icon
                        small
                        class="ml-2"
                      >
                        fa fa-chevron-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="copy in books.filter(obj => obj.isActive && obj.baseTitle === item.baseTitle)"
                      :key="copy._id"
                      @click="$router.push('/intern/buecher/' + copy._id)"
                    >
                      <v-list-item-title
                        :class="(copy.rental && copy.rental !== null) ? 'warning--text' : 'primary--text'"
                      >
                        {{copy.copyTitle}}&nbsp;
                        {{(copy.rental && copy.rental !== null) ? '- verliehen' : ''}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-btn
                  v-if="
                    !item.copyTitle || item.copyTitle === '' &&
                    books.filter(obj => obj.isActive && obj.baseTitle === item.baseTitle && (!obj.rental || obj.rental === 'null')).length > 0 &&
                    ac &&
                    ac.can(user.role).createAny('rental').granted
                  "
                  color="primary"
                  x-small
                  fab
                  class="elevation-2 ml-3"
                  @click="$router.push('/intern/buecher/' + item._id)"
                >
                  <v-icon
                    small
                  >fas fa-book-reader</v-icon>
                </v-btn>
            </template>
          </template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'books',
  data () {
    return {
      showArchivedBooks: false,
      selectedAudience: 'Alle',
      activeBook: null,
      showBookDialog: false,
      viewMode: 'grid',
      search: '',
      showDuplicates: false,
      headers: [
        { text: 'Titel', value: 'baseTitle' },
        { text: 'ISBN', value: 'isbn' },
        { text: 'Bewertung', value: 'rating.avg' },
        { text: 'Stimmen', value: 'rating.count' },
        { text: 'Verfügbarkeit', value: 'rental' }
      ],
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: []
    }
  },
  mounted () {
    // Save current query
    this.$router.options.tmpQuery = this.$route.query
    // Process query
    if (this.$route.query.p) {
      this.page = parseInt(this.$route.query.p)
    }
    if (this.$route.query.i) {
      this.itemsPerPage = parseInt(this.$route.query.i)
    }
    if (this.$route.query.s) {
      this.sortBy = this.$route.query.s.split(',')
    }
    if (this.$route.query.d) {
      let tmpDesc = this.$route.query.d.split(',')
      for (let i = 0; i < tmpDesc.length; i++) {
        if (tmpDesc[i] === 'true') {
          tmpDesc[i] = true
        } else if (this.$route.query.d === 'false') {
          tmpDesc[i] = false
        }
      }
      this.sortDesc = tmpDesc
    }
    if (
      localStorage.getItem('booksViewMode') &&
      this.ac && this.ac.can(this.user.role).readAny('vote').granted
    ) {
      this.viewMode = localStorage.getItem('booksViewMode', this.viewMode)
    } else {
      localStorage.setItem('booksViewMode', this.viewMode)
    }
    if (
      localStorage.getItem('booksShowDuplicates') &&
      this.ac && this.ac.can(this.user.role).updateAny('book').granted
    ) {
      if (localStorage.getItem('booksShowDuplicates') === 'true') {
        this.showDuplicates = true
      } else {
        this.showDuplicates = false
      }
    } else {
      localStorage.setItem('booksShowDuplicates', this.showDuplicates)
    }
    if (
      localStorage.getItem('booksShowArchived') &&
      this.ac && this.ac.can(this.user.role).updateAny('book').granted
    ) {
      if (localStorage.getItem('booksShowArchived') === 'true') {
        this.showArchivedBooks = true
      } else {
        this.showArchivedBooks = false
      }
    } else {
      localStorage.setItem('booksShowArchived', this.showArchivedBooks)
    }
  },
  computed: {
    computedBooks () {
      let tmpBooks
      if (this.showDuplicates) {
        tmpBooks = this.books
      } else {
        tmpBooks = this.computedReducedBooks
      }
      return this.sortByString(
        tmpBooks.filter(
          obj =>
            (
              obj.isActive ||
              this.showArchivedBooks
            ) &&
            (
              obj.audience === 'Alle' ||
              obj.audience === this.selectedAudience ||
              this.selectedAudience === 'Alle'
            ) &&
            (
              !this.search ||
              this.search === '' ||
              obj.baseTitle.toLowerCase().includes(this.search.toLowerCase()) ||
              (obj.isbn && obj.isbn.includes(this.search))
            )
        ), 'baseTitle'
      )
    },
    computedReducedBooks () {
      return this.books.filter((object, index) => index === this.books.findIndex(obj => obj.baseTitle === object.baseTitle))
    },
    computedServerUrl () {
      return process.env.VUE_APP_SERVER_URL
    },
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('books', {
      'books': 'list'
    }),
    ...mapGetters('auth', {
      'user': 'user'
    })
  },
  methods: {
    updatePage (data) {
      if (parseInt(this.$route.query.p) !== data) {
        this.$router.replace({ query: {
          p: data,
          i: this.itemsPerPage,
          s: this.sortBy.join(','),
          d: this.sortDesc.join(',')
        } })
      }
    },
    updateItemsPerPage (data) {
      if (parseInt(this.$route.query.i) !== data) {
        this.$router.replace({ query: {
          p: this.page,
          i: data,
          s: this.sortBy.join(','),
          d: this.sortDesc.join(',')
        } })
      }
    },
    updateSortBy (data) {
      let tmpData
      if (Array.isArray(data)) {
        tmpData = data.join(',')
      } else {
        tmpData = data
      }
      if (data && this.$route.query.s !== tmpData) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          s: tmpData,
          d: this.sortDesc.join(',')
        } })
      } else if (!data) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          d: this.sortDesc.join(',')
        } })
      }
    },
    updateSortDesc (data) {
      let tmpData
      if (Array.isArray(data)) {
        tmpData = data.join(',')
      } else {
        tmpData = data
      }
      if (data && this.$route.query.d !== tmpData) {
        this.$router.replace({ query: {
          p: this.page,
          i: this.itemsPerPage,
          s: this.sortBy.join(','),
          d: tmpData
        } })
      }
    },
    setActiveBook (book) {
      if (this.activeBook) {
        if (this.activeBook._id === book._id) {
          this.activeBook = null
        } else {
          this.activeBook = book
        }
      } else {
        this.activeBook = book
      }
    }
  },
  watch: {
    activeBook () {
      if (this.activeBook) {
        this.showBookDialog = true
      } else {
        this.showBookDialog = false
      }
    },
    showBookDialog () {
      if (!this.showBookDialog) {
        this.activeBook = null
      }
    },
    viewMode () {
      localStorage.setItem('booksViewMode', this.viewMode)
    },
    showDuplicates () {
      localStorage.setItem('booksShowDuplicates', this.showDuplicates)
      if (!this.showDuplicates) {
        this.headers.splice(2, 0, { text: 'Bewertung', value: 'rating.avg' })
      } else {
        this.headers.splice(2, 1)
      }
    },
    showArchivedBooks () {
      localStorage.setItem('booksShowArchived', this.showArchivedBooks)
    },
    '$route.query' () {
      this.$router.options.tmpQuery = this.$route.query
    }
  }
}
</script>
