<template>
  <div>
    <!-- User info -->
    <v-row>
      <v-col
        align-self="center"
        class="display-3"
        cols="12"
        md="8"
      >
        {{computedUser.firstName}} {{computedUser.lastName}}
        <v-btn
          class="ml-3 mb-2"
          icon
          color="primary"
          v-if="
            ac.can(user.role).updateAny('customer').granted ||
            (
              ac.can(user.role).updateOwn('customer').granted &&
              user.role._id === computedUser._id
            )
          "
          @click="$router.push('/intern/nutzer/editor/' + computedUser._id)"
        >
          <v-icon>
            fa fa-edit
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        align-self="center"
        class="title"
        cols="12"
      >
        Ausweis-Nr. {{computedUser.customerId}}
      </v-col>
    </v-row>
    <!-- Rent -->
    <v-row
      v-if="ac.can(user.role).createAny('rental').granted"
    >
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-1">
                Verleihen
              </v-col>
            </v-row>
            <v-row>
              <v-col
                align-self="center"
                cols="12"
                sm="8"
              >
                <v-autocomplete
                  v-model="selectedBook"
                  :items="computedBooks"
                  item-value="_id"
                  label="Buch auswählen"
                  :filter="$filterBook"
                >
                  <template slot="selection" slot-scope="data">
                    {{ data.item.baseTitle }} {{ data.item.copyTitle }}
                  </template>
                  <template slot="item" slot-scope="data">
                    {{ data.item.baseTitle }} {{ data.item.copyTitle }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                align-self="center"
                class="text-right"
                cols="12"
                sm="4"
              >
                <v-btn
                  :disabled="
                    !selectedBook ||
                    !(
                      (
                        ac.can(user.role).createAny('rental').granted ||
                        (
                          ac.can(user.role).createOwn('rental').granted &&
                          user._id === computedUser._id
                        )
                      )
                    )
                  "
                  @click="saveRental()"
                  color="success"
                  block
                >
                  <v-icon class="mr-3">fa fa-book</v-icon>
                  Verleihen
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Current rental -->
    <v-row
      v-for="rentedBook in books.filter(obj => obj.rental && obj.rental.customer === computedUser._id)"
      :key="rentedBook._id"
    >
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-1">
                Aktuell {{computedUser._id === user._id ? 'ausgeliehen' : 'verliehen'}}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="title">
                {{rentedBook.baseTitle}} {{rentedBook.copyTitle}}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                {{computedUser._id === user._id ? 'Ausgeliehen' : 'Verliehen'}} am {{$moment(rentedBook.rental.createdAt).format('D.M.YYYY, HH:mm')}} Uhr
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <template v-if="$moment(rentedBook.rental.createdAt).add(settings[0].defaultRentalDays, 'days').add(rentedBook.rental.extends.length, 'weeks').isBefore($moment(settings[0].rentalEnd).endOf('day'), 'day')">
                  Fällig am {{$moment(rentedBook.rental.createdAt).startOf('day').add(settings[0].defaultRentalDays + (rentedBook.rental.extends.length * 7), 'days').format('dddd, D.M.YYYY')}}
                </template>
                <template v-else>
                  Fällig am {{$moment(settings[0].rentalEnd).format('dddd, D.M.YYYY')}} (Ende der Lesesaison)
                </template>
              </v-col>
            </v-row>
            <template
              v-if="
                $moment().isAfter(
                  $moment(rentedBook.rental.createdAt).endOf('day').add(settings[0].defaultRentalDays + (rentedBook.rental.extends.length * 7), 'days')
                )
              "
            >
              <v-row dense>
                <v-col
                  class="error--text"
                >
                  Rückgabe ist um {{
                    Math.ceil(calcedDaysFromNow(rentedBook.rental) / 7) }} Woche{{ calcedDaysFromNow(rentedBook.rental) > 1 ? 'n': '' }} verspätet ({{calcedDaysFromNow(rentedBook.rental)}} Tag{{calcedDaysFromNow(rentedBook.rental) > 1 ? 'e' : ''}})
                  <v-icon
                    class="ml-3"
                    color="error"
                    x-small
                  >
                    fa fa-exclamation-triangle
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template
              v-if="rentedBook.rental.extends.length > 0"
            >
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  Bereits verlängert am:
                </v-col>
              </v-row>
              <v-row
                dense
              >
                <v-col>
                  <div
                    :class="extend.late ? 'error--text' : ''"
                    v-for="(extend, i) in rentedBook.rental.extends"
                    :key="i"
                  >
                    {{ $moment(extend.dt).format('DD.MM.YYYY') }}
                    <v-icon
                      v-if="extend.late"
                      x-small
                      color="error"
                      class="ml-1 mb-1"
                    >
                      fas fa-exclamation-triangle
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template
              v-if="
                $moment().isAfter(
                  $moment(rentedBook.rental.createdAt).endOf('day').add(settings[0].defaultRentalDays + (rentedBook.rental.extends.length * 7), 'days')
                ) ||
                rentedBook.rental.extends.filter(obj => obj.late).length > 0
              "
            >
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  Mahngebühren:
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <template v-if="rentedBook.rental.payed">
                    Bezahlt ({{ (parseInt(Math.ceil(calcedDaysFromNow(rentedBook.rental) / 7)) > 0 ? parseInt(Math.ceil(calcedDaysFromNow(rentedBook.rental) / 7)) : 0) + rentedBook.rental.extends.filter(obj => obj.late).length }} €)
                  </template>
                  <template v-else>
                    <span
                      class="error--text"
                    >
                      Betrag offen ({{ (parseInt(Math.ceil(calcedDaysFromNow(rentedBook.rental) / 7)) > 0 ? parseInt(Math.ceil(calcedDaysFromNow(rentedBook.rental) / 7)) : 0) + rentedBook.rental.extends.filter(obj => obj.late).length }} €)
                    </span>
                  </template>
                  <template
                    v-if="
                      ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                      ac.can(user.role).updateAny('rental').attributes.includes('payed') ||
                      (
                        user._id === rentedBook.rental.customer &&
                        (
                          ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                          ac.can(user.role).updateOwn('rental').attributes.includes('payed')
                        )
                      )
                    "
                  >
                    <v-btn
                      v-if="!rentedBook.rental.payed"
                      class="ml-3"
                      outlined
                      color="error"
                      @click="payRental(true, rentedBook.rental._id)"
                    >
                      <v-icon
                        small
                        class="mr-3"
                      >
                        fas fa-coins
                      </v-icon>
                      Als bezahlt markieren
                    </v-btn>
                  </template>
                  <v-btn
                    v-if="rentedBook.rental.payed"
                    class="ml-3"
                    outlined
                    color="error"
                    @click="payRental(false, rentedBook.rental._id)"
                  >
                    <v-icon
                      small
                      class="mr-3"
                    >
                      fas fa-coins
                    </v-icon>
                    Als offen markieren
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions
            class="pa-4 py-6"
          >
            <template
              v-if="((rentedBook.rental && rentedBook.rental.extends) ? rentedBook.rental.extends.length : 0) < settings[0].maxExtends && $moment(rentedBook.rental.createdAt).add(settings[0].defaultRentalDays, 'days').add(rentedBook.rental.extends.length, 'weeks').isBefore($moment(settings[0].rentalEnd).startOf('day'))"
            >
              <v-btn
                v-if="
                  ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                  ac.can(user.role).updateAny('rental').attributes.includes('extends') ||
                  (
                    user._id === rentedBook.rental.customer &&
                    (
                      ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                      ac.can(user.role).updateOwn('rental').attributes.includes('extends')
                    )
                  )
                "
                @click="extendRental(rentedBook.rental)"
                color="warning"
              >
                <v-icon class="mr-3">fa fa-clock</v-icon>
                Verlängern (noch {{settings[0].maxExtends - ((rentedBook.rental && rentedBook.rental.extends) ? rentedBook.rental.extends.length : 0)}}x möglich)
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              @click="endRental(rentedBook.rental)"
              color="success"
              v-if="
                  ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                  ac.can(user.role).updateAny('rental').attributes.includes('returned') ||
                  (
                    user._id === rentedBook.rental.customer &&
                    (
                      ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                      ac.can(user.role).updateOwn('rental').attributes.includes('returned')
                    )
                  )
                "
            >
              <v-icon class="mr-3">fa fa-undo</v-icon>
              Zurück geben
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col class="display-1">
                Verlauf
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :headers="headers"
            :items="rentals.filter(obj => obj.returned)"
            :server-items-length="computedTotal"
            :search="search"
            :options.sync="options"
            :must-sort="true"
            :loading="loading"
            class="multiline"
          >
            <template
              v-slot:item.createdAt="{ item }"
            >
              {{ $moment(item.createdAt).format('DD.MM.YYYY') }}
            </template>
            <template
              v-slot:item.book="{ item }"
            >
              {{ getBook(item.book).baseTitle }} {{ getBook(item.book).copyTitle }}
            </template>
            <template
              v-slot:item.returned="{ item }"
            >
              {{ item.returned ? $moment(item.returned).format('DD.MM.YYYY') : '-' }}
            </template>
            <template
              v-slot:item.extends="{ item }"
            >
              <template
                v-if="item.extends.length > 0"
              >
                <v-row
                  v-for="(extend, i) in item.extends"
                  :key="i"
                >
                  <v-col
                    :class="extend.late ? 'error--text py-0' : 'py-0'"
                  >
                    {{
                      $moment(extend.date).format('DD.MM.YYYY')
                    }}
                  </v-col>
                </v-row>
              </template>
              <template
                v-else
              >
                -
              </template>
            </template>
            <template
              v-slot:item.delayed="{ item }"
            >
              {{
                calcedDaysFromReturned(item) > 0 ?
                Math.ceil(calcedDaysFromReturned(item) / 7) + (Math.ceil(calcedDaysFromReturned(item) / 7) > 1 ? ' Wochen' : ' Woche') : '-'
              }}
            </template>
            <template
              v-slot:item.payed="{ item }"
            >
              <template
                v-if="calcedDaysFromReturned(item) > 0 ||  item.extends.filter(obj => obj.late).length > 0"
              >
                <template
                  v-if="item.payed"
                >
                  Bezahlt ({{ (calcedDaysFromReturned(item) > 0 ? Math.ceil(calcedDaysFromReturned(item) / 7) : 0) + item.extends.filter(obj => obj.late).length }} €)
                  <template
                    v-if="
                      ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                      ac.can(user.role).updateAny('rental').attributes.includes('payed') ||
                      (
                        user._id === item.customer &&
                        (
                          ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                          ac.can(user.role).updateOwn('rental').attributes.includes('payed')
                        )
                      )
                    "
                  >
                    <v-btn
                      class="ml-3"
                      small
                      outlined
                      icon
                      color="error"
                      @click="payRental(false, item._id)"
                    >
                      <v-icon
                        small
                      >
                        fas fa-times
                      </v-icon>
                    </v-btn>
                  </template>
                </template>
                <template
                  v-else
                >
                  Offen&nbsp;
                  ({{ (calcedDaysFromReturned(item) > 0 ? Math.ceil(calcedDaysFromReturned(item) / 7) : 0) + item.extends.filter(obj => obj.late).length }} €)
                  <template
                    v-if="
                      ac.can(user.role).updateAny('rental').attributes.includes('*') ||
                      ac.can(user.role).updateAny('rental').attributes.includes('payed') ||
                      (
                        user._id === item.customer &&
                        (
                          ac.can(user.role).updateOwn('rental').attributes.includes('*') ||
                          ac.can(user.role).updateOwn('rental').attributes.includes('payed')
                        )
                      )
                    "
                  >
                    <v-btn
                      class="ml-3"
                      small
                      outlined
                      icon
                      color="error"
                      @click="payRental(true, item._id)"
                    >
                      <v-icon
                        small
                      >
                        fas fa-coins
                      </v-icon>
                    </v-btn>
                  </template>
                </template>
              </template>
              <template
                v-else
              >
                -
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'

export default {
  name: 'book',
  mixins: [makeFindMixin({ service: 'rentals', watch: true })],
  data: function () {
    return {
      selectedBook: null,
      search: '',
      headers: [
        { text: 'Ausgeliehen', value: 'createdAt' },
        { text: 'Buch', value: 'book' },
        { text: 'Zurück am', value: 'returned' },
        { text: 'Verlängert am', value: 'extends' },
        { text: 'Verspätet', value: 'delayed' },
        { text: 'Bezahlt', value: 'payed' }
      ],
      totalRentals: 0,
      loading: true,
      options: { sortBy: ['createdAt'], sortDesc: [true] },
      skip: 0,
      limit: 10,
      sortBy: 'createdAt',
      sortDesc: 1
    }
  },
  watch: {
    rentals: {
      handler () {
        this.loading = false
      },
      deep: true
    },
    options: {
      handler () {
        this.loading = true
        this.skip = (this.options.page - 1) * this.options.itemsPerPage
        this.limit = this.options.itemsPerPage
        this.sortBy = this.options.sortBy[0]
        if (this.options.sortDesc !== undefined) {
          this.sortDesc = this.options.sortDesc[0] === true ? -1 : 1
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('books', {
      'books': 'list',
      'getBook': 'get'
    }),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('users', {
      'getUser': 'get'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    }),
    rentalsParams () {
      return { query: { customer: (this.computedUser._id ? this.computedUser._id : null), $limit: this.limit, $skip: this.skip, $sort: { [this['sortBy']]: this.sortDesc } } }
    },
    computedTotal () {
      try {
        return this.rentalsPaginationData.default.mostRecent.total
      } catch (e) {
        return 0
      }
    },
    computedUser () {
      return this.getUser(this.$route.params.id)
    },
    computedBooks () {
      return this.sortByString(this.books.filter(
        obj => obj.isActive && (!obj.rental || obj.rental === null)
      ), 'baseTitle')
    }
  },
  methods: {
    ...mapActions('rentals', {
      'createRental': 'create',
      'patchRental': 'patch'
    }),
    ...mapActions('books', {
      'patchBook': 'patch'
    }),
    async saveRental () {
      const bookId = this.selectedBook
      const map = {
        customer: this.computedUser._id,
        book: bookId
      }
      const rental = await this.createRental(map)
      await this.patchBook([bookId, { rental: rental._id }, { query: { $populate: ['rental', 'votes'] } }])
      this.selectedBook = null
    },
    payRental (value, id) {
      this.patchRental([id, { payed: value }, {}])
    },
    async endRental (rental) {
      await this.patchRental([rental._id, { returned: Date.now() }, {}])
      await this.patchBook([rental.book, { rental: null }, { query: { $populate: ['rental', 'votes'] } }])
      this.findRentals()
    },
    extendRental (rental) {
      const tmpExtends = JSON.parse(JSON.stringify(rental.extends))
      const tmpLate = Math.ceil(this.calcedDaysFromNow(rental) / 7) > 0
      tmpExtends.push({
        dt: new Date(),
        late: tmpLate
      })
      this.patchRental([rental._id, { extends: tmpExtends }, {}])
    },
    calcedDaysFromNow (rental) {
      return parseInt(this.$moment().endOf('day').diff(
        this.$moment(
          rental.createdAt).endOf('day').add(this.settings[0].defaultRentalDays + (rental.extends.length * 7), 'days'
        ),
        'days'
      ))
    },
    calcedDaysFromReturned (rental) {
      return parseInt(this.$moment(rental.returned).endOf('day')
        .diff(
          this.$moment(rental.createdAt).endOf('day')
            .add(this.settings[0].defaultRentalDays + (rental.extends.length * 7), 'days'),
          'days'
        )
      )
    }
  }
}
</script>
