var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"display-3",attrs:{"align-self":"center"}},[_vm._v(" NUTZER|NNEN EDITOR ")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Vorname*","rules":[_vm.rules.required],"disabled":!(
                        _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                        _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('firstName') ||
                        (
                          _vm.computedUser &&
                          _vm.user._id === _vm.computedUser._id &&
                          (
                            _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                            _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('firstName')
                          )
                        )
                      )},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nachname*","rules":[_vm.rules.required],"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('lastName') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('lastName')
                        )
                      )
                    )},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Passwort*","placeholder":_vm.computedUser ? '********': '',"type":"password","aria-disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('password') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('password')
                        )
                      )
                    )},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Benutzername*","rules":[_vm.rules.loginName, _vm.rules.required],"error-messages":_vm.loginNameErrors,"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('loginName') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('loginName')
                        )
                      )
                    )},model:{value:(_vm.loginName),callback:function ($$v) {_vm.loginName=$$v},expression:"loginName"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Email*","rules":[_vm.rules.required],"error-messages":_vm.emailErrors,"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('email') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('email')
                        )
                      )
                    )},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Telefon","type":"number","rules":[_vm.rules.phone],"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('phone') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('phone')
                        )
                      )
                    )},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"birthdayMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-text-field',_vm._g({attrs:{"value":_vm.computedBirthday,"label":"Geburtstag","readonly":""}},on))]}}]),model:{value:(_vm.showBirthdayMenu),callback:function ($$v) {_vm.showBirthdayMenu=$$v},expression:"showBirthdayMenu"}},[_c('v-date-picker',{ref:"birthdayPicker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01","color":"primary"},on:{"change":_vm.save},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1)],1)],1),_c('v-row',[_c('v-col',[(_vm.$route.params.id !== 'neu')?_c('v-text-field',{attrs:{"rules":_vm.role === 'customer' ? [_vm.rules.required] : [],"label":_vm.role === 'customer' ? 'Ausweis-Nummer*': 'Ausweis-Nummer',"error-messages":_vm.customerIdErrors,"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('customerId') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('customerId')
                        )
                      )
                    )},model:{value:(_vm.customerId),callback:function ($$v) {_vm.customerId=$$v},expression:"customerId"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.computedRoles,"item-value":"role","item-text":"text","label":"Rolle auswählen","rules":[_vm.rules.required],"disabled":!(
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('*') ||
                      _vm.ac.can(_vm.user.role).updateAny(_vm.role).attributes.includes('role') ||
                      (
                        _vm.computedUser &&
                        _vm.user._id === _vm.computedUser._id &&
                        (
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('*') ||
                          _vm.ac.can(_vm.user.role).updateOwn(_vm.role).attributes.includes('role')
                        )
                      )
                    )},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-4 py-6"},[(_vm.$route.params.id !== 'neu')?[(_vm.computedUser.isActive)?_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){return _vm.archiveUser()}}},[_vm._v(" Archivieren ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.activateUser()}}},[_vm._v(" Aktivieren ")])]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.formValid || _vm.loading,"color":"success","loading":_vm.loading},on:{"click":function($event){return _vm.saveUser()}}},[_vm._v(" Speichern ")])],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }