<template>
  <div>
    <v-container>
      <v-card v-if="computedMode === 'verifying'">
        <v-card-title>
          Verifiziere E-Mail-Adresse
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-progress-circular
                indeterminate
                size="100"
                color="primary"
              >
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="computedMode === 'verified'">
        <v-card-title>
          Verifiziert
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-icon
                size="100px"
                color="success"
              >fas fa-check-circle</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              Vielen Dank für die Bestätigung!
            </v-col>
            <v-col
              cols="12"
              class="pt-0"
            >
              Autmatische Weiterleitung zum Login in {{countdown}} Sekunden ...
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="gotoLogin()">Zum Login</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-form v-model="resetFormValid">
      <v-card v-if="computedMode === 'resetting'">
        <v-card-title>
          Passwort zurücksetzen
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Passwort"
                type="password"
                v-model="password"
                :rules="[rules.required, rules.password]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Passwort wiederholen"
                type="password"
                v-model="passwordConfirm"
                :rules="[rules.required, rules.password, (e) => e === password || 'Passwörter nicht identisch']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-4">
          <v-btn
            @click="resetPassword()"
            :disabled="!resetFormValid"
            block
            color="primary"
          >Passwort ändern</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
      <v-card v-if="computedMode === 'wait'">
        <v-card-title>
          Einen Augenblick bitte
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-progress-circular
                indeterminate
                color="primary"
                size="100"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="computedMode === 'resetted'">
        <v-card-title>
          Passwort erfolgreich geändert
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col>
              <v-icon
                size="100px"
                color="success"
              >fas fa-check-circle</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              Vielen Dank! Du kannst dich nun mit deinem neuen Passwort anmelden!
            </v-col>
            <v-col>
              Autmatische Weiterleitung in {{countdown}} Sekunden ...
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="gotoLogin()">Zum Login</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="computedMode === 'error'">
        <v-card-title>
          Fehler
        </v-card-title>
        <v-card-text class="text-center">
          <v-row>
            <v-col cols="12">
              <v-icon
                @click="gotoLogin()"
                size="100px"
                color="error"
              >fas fa-exclamation-triangle</v-icon>
            </v-col>
            <v-col cols="12">
              {{errorText}}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Verify',
  data: () => ({
    done: 0,
    countdown: 10,
    errorText: '',
    resetFormValid: false,
    password: '',
    passwordConfirm: ''
  }),
  mounted () {
    setTimeout(() => {
      if (this.computedMode === 'verifying') {
        setTimeout(() => {
          this.sendVerifytoServer()
        }, 1000)
      }
    }, 1000)
  },
  methods: {
    ...mapActions('authManagement', {
      'createAuth': 'create'
    }),
    gotoLogin () {
      this.$store.commit('SET_SHOW_LOGIN', 'login')
      this.$router.push('/')
    },
    resetPassword () {
      this.done = 2
      this.createAuth({ action: 'resetPwdLong', value: { token: this.$route.params.token, password: this.password } })
        .then(() => {
          this.done = 1
          const redirectInterval = setInterval(() => {
            this.countdown -= 1
            if (this.countdown === 0) {
              clearInterval(redirectInterval)
              this.gotoLogin()
            }
          }, 1000)
        })
        .catch((error) => {
          this.done = -1
          this.errorText = error.message
        })
    },
    sendVerifytoServer () {
      this.createAuth({
        action: 'verifySignupLong',
        value: this.$route.params.token
      })
        .then((result) => {
          this.done = 1
          const redirectInterval = setInterval(() => {
            this.countdown -= 1
            if (this.countdown === 0) {
              clearInterval(redirectInterval)
              this.gotoLogin()
            }
          }, 1000)
        })
        .catch(() => {
          this.done = -1
          this.errorText = 'Nutzer nicht bekannt oder bereits verifiziert.'
        })
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ]),
    computedMode () {
      if (this.$route.params.type === 'verifizieren' && this.done === 0) {
        return 'verifying'
      } else if (this.$route.params.type === 'verifizieren' && this.done === 1) {
        return 'verified'
      } else if (this.$route.params.type === 'reset' && this.done === 0) {
        return 'resetting'
      } else if (this.$route.params.type === 'reset' && this.done === 1) {
        return 'resetted'
      } else if (this.$route.params.type === 'reset' && this.done === 2) {
        return 'wait'
      } else if (this.done === -1) {
        return 'error'
      } else {
        return ''
      }
    }
  }
}
</script>
