import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
      light: {
        primary: '#79a2c8',
        secondary: '#8b8b8b',
        warning: '#f29400',
        error: '#ff5400',
        info: '#b3c1cc',
        success: '#97bf0d'
      }
    }
  }
})
