<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            align-self="center"
            class="display-3"
          >
            BEWERTUNGS-EDITOR
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-alert
          type="warning"
          v-if="!inTime">
          Es können keine Bewertungen mehr abgegeben oder bearbeitet werden.
        </v-alert>
        <v-card>
          <v-card-text>
            <v-form
              v-model="formValid"
            >
              <v-row>
                <v-col>
                  <v-select
                    v-model="selectedCustomer"
                    :items="computedUsers"
                    item-value="_id"
                    item-text="firstName"
                    label="Leser auswählen"
                    :rules="[rules.required]"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('vote').attributes.includes('*') ||
                        ac.can(user.role).updateAny('vote').attributes.includes('customer')
                      )
                    "
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.firstName }} {{ data.item.lastName }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="book"
                    :items="computedBooks"
                    item-value="_id"
                    item-text="baseTitle"
                    label="Buch auswählen"
                    :rules="[rules.required]"
                    :error-messages="bookError"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="body-1">
                  Bewertung*<br>
                  <span v-if="!this.value" class="body-2 error--text">Pflichteingabe.</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-rating
                    color="primary"
                    v-model="value"
                    hover
                    length="10"
                  ></v-rating>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Zusammenfassung*"
                    v-model="description"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('vote').attributes.includes('*') ||
                        ac.can(user.role).updateAny('vote').attributes.includes('description') ||
                        (
                          selectedCustomer &&
                          user._id === selectedCustomer &&
                          (
                            ac.can(user.role).updateOwn('vote').attributes.includes('*') ||
                            ac.can(user.role).updateOwn('vote').attributes.includes('description')
                          )
                        )
                      )
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    label="Begründung*"
                    v-model="comment"
                    :disabled="
                      !(
                        ac.can(user.role).updateAny('vote').attributes.includes('*') ||
                        ac.can(user.role).updateAny('vote').attributes.includes('comment') ||
                        (
                          selectedCustomer &&
                          user._id === selectedCustomer &&
                          (
                            ac.can(user.role).updateOwn('vote').attributes.includes('*') ||
                            ac.can(user.role).updateOwn('vote').attributes.includes('comment')
                          )
                        )
                      )
                    "
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="pa-4 py-6">
            <template v-if="$route.params.id !== 'neu'">
              <v-btn
                @click="deleteVote(vote._id)"
                color="error"
              >
                Löschen
              </v-btn>
            </template>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="!formValid || !this.value || !inTime"
              @click="saveVote()"
              color="success"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'userEditor',
  data: function () {
    return {
      vote: null,
      formValid: false,
      book: null,
      description: null,
      comment: null,
      value: null,
      selectedCustomer: null,
      customerVotes: [],
      bookError: null
    }
  },
  mounted () {
    this.adapt()
    this.prepareGetVote(this.computedId)
    this.checkVotes()
  },
  computed: {
    ...mapGetters([
      'ac',
      'rules'
    ]),
    ...mapGetters('auth', {
      'user': 'user'
    }),
    ...mapGetters('books', {
      'books': 'list',
      'getBook': 'get'
    }),
    ...mapGetters('users', {
      'getUser': 'get',
      'users': 'list'
    }),
    ...mapGetters('votes', {
      'getVote': 'get'
    }),
    ...mapGetters('settings', {
      'settings': 'list'
    }),
    computedId () {
      return this.$route.params.id
    },
    computedReducedBooks () {
      return this.books.filter((object, index) => index === this.books.findIndex(obj => obj.baseTitle === object.baseTitle))
    },
    computedBooks () {
      return this.sortByString(this.computedReducedBooks.filter(
        obj => obj.isActive
      ), 'baseTitle')
    },
    computedUsers: function () {
      return this.sortByString(this.users.filter(
        obj => obj.customerId && obj.customerId !== '' && obj.isActive
      ), 'firstName')
    },
    inTime () {
      return this.$moment().isBefore(this.$moment(this.settings[0].votingEnd).endOf('day'))
    }
  },
  watch: {
    async computedId () {
      this.prepareGetVote(this.computedId)
    },
    vote () {
      this.adapt()
    },
    selectedCustomer () {
      this.bookError = null
      this.checkVotes()
    },
    book () {
      this.bookError = null
      this.checkVotes()
    }
  },
  methods: {
    ...mapActions('votes', {
      'createVote': 'create',
      'patchVote': 'patch',
      'removeVote': 'remove',
      'requestVote': 'get',
      'requestVotes': 'find'
    }),
    ...mapActions('books', {
      'reloadBook': 'get'
    }),
    ...mapActions('users', {
      'patchUser': 'patch'
    }),
    adapt () {
      if (this.vote) {
        this.description = this.vote.description
        this.comment = this.vote.comment
        this.value = this.vote.value
        this.selectedCustomer = this.vote.customer
        this.book = this.vote.book
      } else {
        this.selectedCustomer = this.user._id
      }
    },
    async prepareGetVote (id) {
      if (id && id !== 'neu') {
        let tmpVote = this.getVote(id)
        if (!tmpVote) {
          tmpVote = await this.requestVote(this.computedId)
        }
        this.vote = tmpVote
      }
    },
    async saveVote () {
      const map = {
        description: this.description,
        comment: this.comment,
        value: this.value,
        book: this.book,
        bookTitle: this.getBook(this.book).baseTitle,
        customer: this.selectedCustomer,
        customerName: this.getUser(this.selectedCustomer).firstName + ' ' + this.getUser(this.selectedCustomer).lastName
      }
      if (this.$route.params.id === 'neu') {
        await this.createVote(map)
      } else {
        await this.patchVote([this.vote._id, map, {}])
      }
      await this.reloadBook([this.book, { query: { $populate: ['votes'] } }])
      await this.patchUser([this.selectedCustomer, {}, { query: { $populate: ['votes'] } }])
      this.$router.push({ path: '/intern/bewertungen', query: this.$router.options.tmpQuery })
    },
    async deleteVote (id) {
      await this.removeVote(id)
      await this.reloadBook([this.book, { query: { $populate: ['rental', 'votes'] } }])
      await this.patchUser([this.selectedCustomer, {}, { query: { $populate: ['votes'] } }])
      this.$router.push({ path: '/intern/bewertungen', query: this.$router.options.tmpQuery })
    },
    async checkVotes () {
      if (this.selectedCustomer && this.book && this.$route.params.id === 'neu') {
        this.customerVotes = await this.requestVotes({ query: { customer: this.selectedCustomer, book: this.book }, limit: 1 })
        if (this.customerVotes.total > 0) {
          this.bookError = 'Dieses Buch hast du schon bewertet.'
        }
      }
    }
  }
}
</script>
