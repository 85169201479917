import Vue from 'vue'
import feathersClient from '@/feathers-client'
const loggerService = feathersClient.service('logger')

const globals = {
  install (Vue) {
    Vue.prototype.sortByString = (unsortedArray, property) => {
      return unsortedArray.sort((a, b) => {
        if (a[property].toLowerCase() < b[property].toLowerCase()) { return -1 }
        if (a[property].toLowerCase() > b[property].toLowerCase()) { return 1 }
        return 0
      })
    }
    Vue.prototype.$filterFullName = (item, queryText, itemText) => {
      try {
        const textOne = item.firstName.toLowerCase()
        const textTwo = item.lastName.toLowerCase()
        const searchText = queryText.toLowerCase().trim()
        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1 ||
          (textOne + ' ' + textTwo).indexOf(searchText) > -1 ||
          (textOne + textTwo).indexOf(searchText) > -1
      } catch (error) {
        loggerService.create({ type: 'error', text: 'Error in full name filter ', error })
      }
    }
    Vue.prototype.$filterBook = (item, queryText, itemText) => {
      try {
        const title = item.baseTitle.toLowerCase()
        const searchText = queryText.toLowerCase().trim()
        return title.indexOf(searchText) > -1
      } catch (error) {
        loggerService.create({ type: 'error', text: 'Error in book filter ', error })
      }
    }
  }
}

Vue.use(globals)
