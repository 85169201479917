<template>
  <div>
    <v-row>
      <v-col>
        <v-row>
          <v-col
              align-self="center"
              class="display-3"
            >
              IMPRESSUM
            </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="pt-5 px-5">
          <v-card-text>
            <v-row>
              <v-col class="headline">
                Angaben gemäß § 5 TMG:
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="title">Vertreten durch:</div><br>
                GEORG BAIER<br>
                KATHRIN PRZEWODNIK<br>
                LUCIA GEITMANN
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="title">Eintragung im Vereinsregister:</div><br>
                Registergericht: Amtsgericht Ulm<br>
                Registernummer: VR 482
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col class="headline">
                Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Kathrin Köhn<br>
                Stadtjugendring Ulm e.V.<br>
                Schillerstr. 1/ 4<br>
                89077 Ulm<br><br>
                Telefon: 0731-14069-17<br>
                E-Mail: koehn@sjr-ulm.de
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapGetters('users', {
      'users': 'list'
    })
  }
}
</script>
